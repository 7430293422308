import { Notification, toaster } from 'rsuite';
import { MessageType } from 'rsuite/esm/Notification/Notification';
import { PlacementType } from 'rsuite/esm/toaster/ToastContainer';

const buildNotification = (config: INotificationConfig) => {
  return (
    <Notification type={config.type} header={config.title} closable={true}>
      {config.body}
    </Notification>
  );
};

const push = (config: INotificationConfig): string => {
  const key = toaster.push(buildNotification(config), {
    placement: config.placement ?? 'topEnd',
    duration: config.duration ?? 4500
  });

  return key as unknown as string; //somehow key is nullable, temporarily ignoring that /Renat
};

const close = (key: string): void => {
  toaster.remove(key);
};

const closeAll = (): void => {
  toaster.clear();
};

export const notificationHelper = {
  push,
  close,
  closeAll
};

export interface INotificationConfig {
  type: MessageType;
  title: React.ReactNode;
  body?: React.ReactNode;
  placement?: PlacementType;
  duration?: number;
}
