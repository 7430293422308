function getEnvironmentVariable(key: string, required: boolean): string | undefined;
function getEnvironmentVariable(key: string): string;
function getEnvironmentVariable(key: string, required?: boolean): string | undefined {
  const value = import.meta.env[key];

  if (!value && required) {
    throw new Error(`Environment variable ${key} is undefined`);
  }

  return value;
}

const environmentSpecified = (env: string) => import.meta.env.MODE === env;
const environmentVariableEquals = (variable: string, expected: string) => getEnvironmentVariable(variable) === expected;

const isRwsEnviromentEnabled = environmentVariableEquals('VITE_IS_RWS_ENVIROMENT_ENABLED', 'true');

export const environment = {
  isDevelopment: environmentSpecified('development'),
  isProduction: environmentSpecified('production'),
  isTest: environmentSpecified('test'),
  apiAddress: getEnvironmentVariable('VITE_API_ADDRESS'),
  appInsightsKey: getEnvironmentVariable('VITE_APP_INSIGHTS', false),
  mainLayerUrl: getEnvironmentVariable('VITE_MAIN_LAYER_URL'),
  webMapId: getEnvironmentVariable('VITE_WEB_MAP_ID'),
  authAuthority: getEnvironmentVariable('VITE_AUTH_AUTHORITY'),
  authClientId: getEnvironmentVariable('VITE_AUTH_CLIENT_ID'),
  authAudience: getEnvironmentVariable('VITE_AUTH_AUDIENCE'),
  arcGisRestEndpoint: getEnvironmentVariable('VITE_ARCGIS_REST_ENDPOINT'),
  tokenDelayInMinutes: Number(getEnvironmentVariable('VITE_TOKEN_DELAY_IN_MINUTES')),
  isRwsEnviromentEnabled: isRwsEnviromentEnabled,
  esriPortalUrl: getEnvironmentVariable('VITE_ESRI_PORTAL_URL', isRwsEnviromentEnabled),
  wreckIconScale: Number(getEnvironmentVariable('VITE_WRECK_ICON_SCALE')),
  wreckIconValues: getEnvironmentVariable('VITE_WRECK_ICON_VALUES')
    .split(',')
    .map(x => Number(x)),
  userRoleName: getEnvironmentVariable('VITE_AUTH_USER_ROLE_NAME'),
  dataAdminRoleName: getEnvironmentVariable('VITE_AUTH_DATAADMIN_ROLE_NAME'),
  appAdminRoleName: getEnvironmentVariable('VITE_AUTH_APPADMIN_ROLE_NAME'),
  arcGisMapStartZoomLevel: Number(getEnvironmentVariable('VITE_ARCGIS_MAP_START_ZOOM_LEVEL')),
  arcGisMinZoomLevel: Number(getEnvironmentVariable('VITE_ARCGIS_MAP_MIN_ZOOM_LEVEL')),
  arcGisMaxZoomLevel: Number(getEnvironmentVariable('VITE_ARCGIS_MAP_MAX_ZOOM_LEVEL')),
  arcGisMapCenterLongitude: Number(getEnvironmentVariable('VITE_ARCGIS_MAP_CENTER_LONGITUDE')),
  arcGisMapCenterLatitude: Number(getEnvironmentVariable('VITE_ARCGIS_MAP_CENTER_LATITUDE'))
};
