import { createAction } from '@reduxjs/toolkit';

import { IReferenceCreateModel } from '../../domain/models/referenceCreateModel';
import { IReferenceUpdateModel } from '../../domain/models/referenceUpdateModel';
import { IAuditLogRequest } from '../../services/requestModels/auditLogRequest';
import { IWreckExportRequest } from '../../services/requestModels/wreckExportRequest';
import { IAuditLogResponse } from '../../services/responseModels/auditLogResponse';

const adminStateCleanup = createAction('ADMIN_STATE_CLEAN_UP');

const wreckExportStarted = createAction<IWreckExportRequest>('WRECK_EXPORT_STARTED');
const wreckExportStateChanged = createAction<boolean>('WRECK_EXPORT_STATE_CHANGED');
const wreckExportCancelled = createAction('WRECK_EXPORT_CANCELLED');

const auditLogRequested = createAction<IAuditLogRequest>('AUDIT_LOG_REQUESTED');
const auditLogFetched = createAction<IAuditLogResponse>('AUDIT_LOG_FETCHED');
const auditLogFetchStateChanged = createAction<boolean>('AUDIT_LOG_FETCH_STATE_CHANGED');

const referenceCreate = createAction<IReferenceCreateModel>('REFERENCE_CREATE');
const referenceUpdate = createAction<IReferenceUpdateModel>('REFERENCE_UPDATE');

export const adminActions = {
  adminStateCleanup,
  wreckExportStarted,
  wreckExportStateChanged,
  wreckExportCancelled,
  auditLogRequested,
  auditLogFetched,
  auditLogFetchStateChanged,
  referenceCreate,
  referenceUpdate
};
