import esriconfig from '@arcgis/core/config';

import { environment } from './environment';

//TODO ::: Use enviroment name for isRwsEnviromentEnabled instead of true/false. EG. Azure, RWS, local etc. /Daan
function setEsriEnvironment() {
  const portalUrl = environment.esriPortalUrl;

  if (environment.isRwsEnviromentEnabled && portalUrl !== undefined) {
    esriconfig.portalUrl = portalUrl;
  }
}

export default setEsriEnvironment;
