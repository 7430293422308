import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'rsuite';

const getLoaderStyle = (props: ILoaderComponentProps) =>
  css({
    display: props.visible ?? true ? 'flex' : 'none',
    opacity: props.opacity ?? 1,
    backgroundColor: 'white',
    zIndex: 7
  });

const LoaderComponent: React.FC<ILoaderComponentProps> = (props: ILoaderComponentProps) => {
  const { t } = useTranslation();

  return <Loader css={getLoaderStyle(props)} center={true} content={props.content ?? t('Loading...')} />;
};

export default LoaderComponent;

export interface ILoaderComponentProps {
  visible?: boolean;
  opacity?: number;
  content?: React.ReactNode;
}
