import { all } from 'redux-saga/effects';

import { adminSaga } from './sagas/adminSaga';
import { commonSaga } from './sagas/commonSaga';
import { contactDetailsSaga } from './sagas/contactDetailsSaga';
import { contactsOverviewSaga } from './sagas/contactsOverviewSaga';
import { notificationSaga } from './sagas/notificationSaga';

export default function* appSaga(): Generator {
  yield all([contactDetailsSaga(), contactsOverviewSaga(), commonSaga(), adminSaga(), notificationSaga()]);
}
