import { useSelector } from 'react-redux';

import { AppState } from '../store/appReducer';
import { LoadingContext, LoadingState } from '../utils/loadingContext';

const LoadingContextProvider: React.FC<ILoadingContextProviderProps> = (props: ILoadingContextProviderProps) => {
  const loadingSlice = useSelector<AppState, LoadingState>(s => s.loadingSlice);

  return <LoadingContext.Provider value={loadingSlice}>{props.children}</LoadingContext.Provider>;
};

export default LoadingContextProvider;

export interface ILoadingContextProviderProps {
  children: React.ReactElement;
}
