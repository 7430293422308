import { useTranslation } from 'react-i18next';

import { centeredContent } from '../../sharedStyles';

const SessionLostComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div css={centeredContent}>
      <h3>{t('Your session has expired. Please reload the page')}</h3>
    </div>
  );
};

export default SessionLostComponent;
