import { createApiClient } from '../utils/httpClient';

import { ICommentCreateRequest } from './requestModels/commentCreateRequest';
import { ICommentResponse } from './responseModels/commentResponse';

export async function getComments(nationalContactNumber: number): Promise<ICommentResponse> {
  const apiClient = createApiClient();
  const url = `api/Comment/${nationalContactNumber}`;

  const response = await apiClient.get<ICommentResponse>(url);

  return response.data;
}

export async function createComment(request: ICommentCreateRequest) {
  const apiClient = createApiClient();
  const url = `api/Comment`;

  await apiClient.post(url, request);
}
