import { createReducer } from '@reduxjs/toolkit';

import { INationalContactInRadiusResponse } from '../../services/responseModels/nationalContactInRadiusResponse';
import { IReferenceResponse } from '../../services/responseModels/referenceResponse';
import { ITokenResponse } from '../../services/responseModels/tokenResponse';
import { commonActions } from '../actions/commonActions';

const initialCommonState: ICommonState = {
  references: [],
  projectionsLoaded: false,
  exportInProgress: false,
  tokenData: undefined,
  contactsInRadius: [],
  contactsInRadiusLoading: false,
  saveInProgress: false,
  userStored: false
};

export interface ICommonState {
  readonly references: IReferenceResponse[];
  readonly projectionsLoaded: boolean;
  readonly exportInProgress: boolean;
  readonly tokenData?: ITokenResponse;
  readonly contactsInRadius: INationalContactInRadiusResponse[];
  readonly contactsInRadiusLoading: boolean;
  readonly saveInProgress: boolean;
  readonly userStored: boolean;
}

export const commonReducer = createReducer(initialCommonState, builder => {
  builder.addCase(commonActions.projectionsLoaded, state => {
    state.projectionsLoaded = true;
  });
  builder.addCase(commonActions.exportStateChanged, (state, action) => {
    state.exportInProgress = action.payload;
  });
  builder.addCase(commonActions.tokenRetrieved, (state, action) => {
    state.tokenData = action.payload;
  });
  builder.addCase(commonActions.contactsInRadiusRequested, state => {
    state.contactsInRadius = [];
    state.contactsInRadiusLoading = true;
  });
  builder.addCase(commonActions.contactsInRadiusRetrieved, (state, action) => {
    state.contactsInRadius = action.payload;
    state.contactsInRadiusLoading = false;
  });
  builder.addCase(commonActions.saveInProgressChanged, (state, action) => {
    state.saveInProgress = action.payload;
  });
  builder.addCase(commonActions.referencesFetched, (state, action) => {
    state.references = action.payload;
  });
  builder.addCase(commonActions.userStored, state => {
    state.userStored = true;
  });
});
