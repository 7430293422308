import { OidcConfiguration } from '@axa-fr/react-oidc';

import { environment } from './environment';

/* eslint-disable @typescript-eslint/naming-convention */
export const oidcConfig: OidcConfiguration = {
  client_id: environment.authClientId,
  redirect_uri: `${window.location.origin}/authentication/callback`,
  scope: 'openid profile email offline_access',
  authority: environment.authAuthority,
  service_worker_relative_url: '/OidcServiceWorker.js',
  service_worker_only: true
};
/* eslint-enable @typescript-eslint/naming-convention */
