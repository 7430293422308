import FileSaver from 'file-saver';

import { createApiClient } from '../utils/httpClient';

import { IGisExportRequest } from './requestModels/gisExportRequest';
import { IWordExportRequest } from './requestModels/wordExportRequest';
import { IWreckExportRequest } from './requestModels/wreckExportRequest';

//TODO ::: Filenames should be taken from the response, but Axios doesn't expose them /Renat

async function createGisExport(model: IGisExportRequest, abortSignal: AbortSignal): Promise<void> {
  const apiClient = createApiClient();

  const response = await apiClient.post<Blob, IGisExportRequest>('api/Export/GIS', model, {
    responseType: 'blob',
    signal: abortSignal
  });

  FileSaver.saveAs(response.data, `${model.filename}.zip`);
}

async function createWordExport(model: IWordExportRequest, abortSignal: AbortSignal): Promise<void> {
  const apiClient = createApiClient();

  const response = await apiClient.post<Blob, IWordExportRequest>('api/Export/Word', model, {
    responseType: 'blob',
    signal: abortSignal
  });

  FileSaver.saveAs(response.data, `${model.filename}.docx`);
}

async function createWreckExport(model: IWreckExportRequest, abortSignal: AbortSignal): Promise<void> {
  const apiClient = createApiClient();

  const response = await apiClient.post<Blob, IWreckExportRequest>('api/Export/Wreck', model, {
    responseType: 'blob',
    signal: abortSignal
  });

  FileSaver.saveAs(response.data, model.filename);
}

export { createGisExport, createWordExport, createWreckExport };
