import { css } from '@emotion/react';

export const styleConstants = {
  primaryColor: '#f9e11e',
  rijkslogo: '#154273',
  tableHeaderColor: '#01689b'
};

export const primaryBackgroundColor = css({
  backgroundColor: styleConstants.primaryColor
});

export const contentHeight = css({
  height: 'calc(100% - 132px - 50px)' //132px = Primary + secondary headers and 50px = Footer /Renat
});

export const tableHeaderColor = css({ color: styleConstants.tableHeaderColor });

export const secondaryHeaderButtonStyle = css({
  height: '44px',
  '&.rs-navbar-item:hover': {
    backgroundColor: '#e7d32f'
  }
});

export const centeredContent = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%'
});

export const navItem = css({
  width: '15%',
  textAlign: 'center',
  background: 'rgb(253, 246, 188)'
});

export const navItemActive = css`
  &.rs-nav-item.rs-nav-item-active {
    background-color: rgb(254, 251, 221);
  }
`;

export const customTableStyle = css`
  & .rs-table-row {
    &.rs-table-row-header {
      border-bottom: 1px solid black;
      color: ${styleConstants.tableHeaderColor};
      font-weight: bold;
      font-size: 14px;
    }

    .rs-table-cell-first {
      font-weight: bold;
    }

    .rs-table-cell {
      cursor: pointer;
    }

    :not(.rs-table-row-header) {
      :hover {
        .rs-table-cell {
          filter: brightness(0.85);
        }
      }

      :nth-of-type(even) .rs-table-cell {
        background-color: white;
      }

      :nth-of-type(odd) .rs-table-cell {
        background-color: #f3f3f2;
      }
    }
  }
`;

//Thin yellow header with margin
const popupHeader = css`
  .esri-popup__header {
    background-color: ${styleConstants.primaryColor};
    margin-bottom: 10px;
  }

  .esri-popup__header h2 {
    line-height: 16px;
  }

  .esri-popup__header-buttons > .esri-popup__button {
    background-color: ${styleConstants.primaryColor};
  }
`;

//Reversed footer with navigation to the left and blue button to the right
const popupFooter = css`
  .esri-popup__button.esri-popup__action {
    background-color: ${styleConstants.rijkslogo};
    color: white;
  }

  .esri-popup__navigation {
    margin: 6px auto 6px 15px;
  }

  .esri-popup__footer {
    flex-direction: row-reverse;
  }

  .esri-popup__footer.esri-popup__footer--has-actions > .esri-popup__inline-actions-container {
    justify-content: flex-end;
  }
`;

//Removes outline as z-index of it way higher of ones of other components - a lot of changes required /Renat
const mapView = css`
  .esri-view .esri-view-surface:focus::after {
    outline: none !important;
  }
`;

//The overridden classes were taken out of the markup, double check UI when @arcgis/core library is updated /Renat
export const esriOverrides = css([popupHeader, popupFooter, mapView]);

export const customLabelStyle = css({
  textAlign: 'left',
  width: '160px',
  display: 'inline-block',
  marginRight: 0,
  padding: '8px 0'
});

export const formLabelStyle = css({
  '.rs-form-group &.rs-form-control-label': customLabelStyle
});

export const formGroupStyle = css({
  '.rs-form.rs-form-horizontal &.rs-form-group': {
    marginBottom: '0px'
  }
});

export const numberInputWithoutButtons = css({
  '& > .rs-input-number-btn-group-vertical': {
    visibility: 'hidden'
  }
});

export const uploaderTextStyle = css({
  '.rs-uploader-trigger.rs-uploader-trigger-customize > &': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    cursor: 'pointer'
  }
});
