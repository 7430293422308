import { IDhyObservationReassignModel } from '../domain/models/dhyObservationReassignModel';
import { IDhyObservationUpdateModel } from '../domain/models/dhyObservationUpdateModel';
import { createApiClient } from '../utils/httpClient';

import { IDhyObservationResponse } from './responseModels/dhyObservationResponse';

export async function getDhyObservations(nationalContactNumber: number): Promise<IDhyObservationResponse[]> {
  const apiClient = createApiClient();
  const url = `api/Dhy/Observations/${nationalContactNumber}`;

  const response = await apiClient.get<IDhyObservationResponse[]>(url);

  return response.data;
}

export async function updateDhyObservation(model: IDhyObservationUpdateModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Dhy/Observations/${model.dhyObservationId}`;

  await apiClient.put(url, model.request);
}

export async function reassignDhyObservation(model: IDhyObservationReassignModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Dhy/Observations/${model.dhyObservationId}/Reassign`;

  await apiClient.patch(url, model.request);
}
