import { createReducer } from '@reduxjs/toolkit';

import { LoadingState } from '../../utils/loadingContext';
import { loadingActions } from '../actions/loadingActions';

const initialState: LoadingState = {};

export const loadingReducer = createReducer(initialState, builder => {
  builder.addCase(loadingActions.enableLoading, (state, action) => {
    state[action.payload] = true;
  });
  builder.addCase(loadingActions.disableLoading, (state, action) => {
    delete state[action.payload];
  });
});
