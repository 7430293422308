import { useOidc } from '@axa-fr/react-oidc';
import { Link, LinkProps } from 'react-router-dom';

import { urlHelper } from '../../utils/urlHelper';

const BackToRootLinkComponent: React.FC<BackToRootLinkComponentProps> = (props: BackToRootLinkComponentProps) => {
  const { isAuthenticated } = useOidc();
  const to = isAuthenticated ? urlHelper.routes.contacts : urlHelper.routes.root;

  return <Link to={to} {...props} />;
};

export default BackToRootLinkComponent;

//'to' property will be calculated, so omiting it here /Renat
export type BackToRootLinkComponentProps = Omit<LinkProps & React.RefAttributes<HTMLAnchorElement>, 'to'>;
