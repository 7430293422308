import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { environment } from './utils/environment';

const reactPlugin = new ReactPlugin();
const appInsightsKey = environment.appInsightsKey;

if (appInsightsKey) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: appInsightsKey,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin]
    }
  });
  appInsights.loadAppInsights();
}

export default reactPlugin;
