import { createApiClient } from '../utils/httpClient';

import { IRwsContactReassignRequest } from './requestModels/rwsContactReassignRequest';
import { IRwsContactUpdateRequest } from './requestModels/rwsContactUpdateRequest';
import { IRwsContactResponse } from './responseModels/rwsContactResponse';

export async function getRwsContact(nationalContactNumber: number): Promise<IRwsContactResponse> {
  const apiClient = createApiClient();
  const url = `api/Rws/${nationalContactNumber}`;

  const response = await apiClient.get<IRwsContactResponse>(url, {
    allowedErrorCodes: [404]
  });

  return response.data;
}

export async function updateRwsContact(request: IRwsContactUpdateRequest): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Rws`;

  await apiClient.put<number, IRwsContactUpdateRequest>(url, request);
}

export async function deleteRwsContact(id: number): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Rws/${id}`;

  await apiClient.delete(url);
}

export async function reassignRwsContact(model: IRwsContactReassignRequest): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Rws/Reassign`;

  await apiClient.patch(url, model);
}
