import { ReactElement, useCallback, useState } from 'react';

import { HeaderButtons } from '../domain/enums/headerButtonType';
import { ToolDrawerState } from '../domain/enums/toolDrawerState';
import { HeaderContext, IHeaderContext } from '../utils/headerContext';

const HeaderContextProviderComponent: React.FC<IHeaderContextProviderProps> = (props: IHeaderContextProviderProps) => {
  const [visibleButtons, setVisibleButtons] = useState<HeaderButtons[]>([]);
  const [drawerState, setDrawerState] = useState<ToolDrawerState>(ToolDrawerState.Closed);
  const [mapVisible, setMapVisible] = useState<boolean>(true);
  const [tableVisible, setTableVisible] = useState<boolean>(true);
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [rwsWizardVisible, setRwsWizardVisible] = useState<boolean>(false);

  const toggleDrawerState = useCallback(
    (value: ToolDrawerState) => {
      if (drawerState === value) {
        setDrawerState(ToolDrawerState.Closed);
      } else {
        setDrawerState(value);
      }
    },
    [drawerState]
  );

  const toggleMap = useCallback(
    (checked: boolean) => {
      if (checked || (!checked && tableVisible)) {
        setMapVisible(checked);
      }
    },
    [tableVisible]
  );

  const toggleTable = useCallback(
    (checked: boolean) => {
      if (checked || (!checked && mapVisible)) {
        setTableVisible(checked);
      }
    },
    [mapVisible]
  );

  const headerContextValue: IHeaderContext = {
    visibleButtons,
    setVisibleButtons,
    drawerState,
    toggleDrawerState,
    tableVisible,
    setTableVisible: toggleTable,
    mapVisible,
    setMapVisible: toggleMap,
    searchActive,
    setSearchActive,
    rwsWizardVisible,
    setRwsWizardVisible
  };

  return <HeaderContext.Provider value={headerContextValue}>{props.children}</HeaderContext.Provider>;
};

export default HeaderContextProviderComponent;

export interface IHeaderContextProviderProps {
  children: ReactElement;
}
