import { OidcProvider } from '@axa-fr/react-oidc';

import { oidcConfig } from '../../utils/authentication';
import { urlHelper } from '../../utils/urlHelper';
import LoaderComponent from '../core/loaderComponent';

import AuthenticatingErrorComponent from './authenticatingErrorComponent';
import ServiceWorkerNotSupportedComponent from './serviceWorkerNotSupportedComponent';
import SessionLostComponent from './sessionLostComponent';
import SignInComponent from './signInComponent';

//Same tab logout is currently handled by logout() method itself /Renat
const onLogoutFromAnotherTab = () => {
  window.location.href = `${window.location.origin}${urlHelper.patterns.signedOut}`;
};

const OidcProviderComponent: React.FC<IOidcProviderComponentProps> = (props: IOidcProviderComponentProps) => {
  return (
    <OidcProvider
      onLogoutFromAnotherTab={onLogoutFromAnotherTab}
      configuration={oidcConfig}
      authenticatingComponent={SignInComponent}
      callbackSuccessComponent={SignInComponent}
      loadingComponent={LoaderComponent}
      sessionLostComponent={SessionLostComponent}
      authenticatingErrorComponent={AuthenticatingErrorComponent}
      serviceWorkerNotSupportedComponent={ServiceWorkerNotSupportedComponent}
    >
      {props.children}
    </OidcProvider>
  );
};

export default OidcProviderComponent;

export interface IOidcProviderComponentProps {
  children: React.ReactNode;
}
