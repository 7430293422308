import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useMemo } from 'react';

import { UserRole } from '../domain/enums/userRole';
import { IAccessTokenPayload } from '../domain/models/accessTokenPayload';
import { environment } from '../utils/environment';

const audience = environment.authAudience;

const environmentRoles = {
  [environment.userRoleName]: UserRole.User,
  [environment.dataAdminRoleName]: UserRole.DataAdmin,
  [environment.appAdminRoleName]: UserRole.AppAdmin
};

export function useUserRoles(): UserRole[] {
  const { accessTokenPayload } = useOidcAccessToken();

  const result = useMemo(() => {
    //Just a plain cast, if any issues appear might be worth doing a proper mapping here /Renat
    const payload = accessTokenPayload as IAccessTokenPayload | null;

    if (!payload?.resource_access || !payload.resource_access[audience]) {
      return [];
    }

    const roles = payload.resource_access[audience].roles.map(x => environmentRoles[x]).filter(x => !!x);

    return roles;
  }, [accessTokenPayload]);

  return result;
}
