import { css } from '@emotion/react';
import { TFunction } from 'i18next';
import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Divider, Panel, PanelGroup } from 'rsuite';

import reactPlugin from '../applicationInsights';

interface IErrorBoundaryComponentProps {
  children: React.ReactNode;
  customErrorContent?: React.ReactNode;
  t: TFunction;
}

interface IErrorBoundaryComponentState {
  error?: Error;
}

const wrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%'
});

const refreshStyle = css({
  textDecoration: 'underline',
  cursor: 'pointer'
});

const panelStyle = css({
  marginTop: '10px',
  minWidth: '230px'
});

const stackStyle = css({
  whiteSpace: 'pre-wrap'
});

const refreshPage = () => window.location.reload();

const renderGeneric = (t: TFunction, error: Error) => {
  return (
    <div css={wrapperStyle}>
      <h2>{t('An unexpected error seems to have occurred')}</h2>
      <div>
        <Trans i18nKey="ErrorSuggestions">
          Try
          <span onClick={refreshPage} css={refreshStyle}>
            refreshing your page
          </span>
          or contact us if the problem persists
        </Trans>
      </div>
      <PanelGroup css={panelStyle} accordion={true} bordered={true}>
        <Panel header={t('Developer information')}>
          <h4>{error.message}</h4>
          <Divider />
          <div css={stackStyle}>{error.stack}</div>
        </Panel>
      </PanelGroup>
    </div>
  );
};

class ErrorBoundaryComponent extends React.PureComponent<IErrorBoundaryComponentProps, IErrorBoundaryComponentState> {
  public constructor(props: IErrorBoundaryComponentProps) {
    super(props);
    this.state = { error: undefined };
  }

  public static getDerivedStateFromError(error: Error): IErrorBoundaryComponentState {
    return { error: error };
  }

  public componentDidCatch(error: Error): void {
    reactPlugin.trackException({ exception: error });
  }

  public render(): React.ReactNode {
    if (this.state.error) {
      return this.props.customErrorContent ?? renderGeneric(this.props.t, this.state.error);
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundaryComponent);
