import { UserRole } from '../../domain/enums/userRole';
import { useUserRoles } from '../../hooks/useUserRoles';

const ProtectedComponent: React.FC<IProtectedComponentProps> = (props: IProtectedComponentProps) => {
  const userRoles = useUserRoles();

  if (userRoles.length === 0 || userRoles.every(role => !props.allowedRoles.includes(role))) {
    return null;
  }

  return <>{props.children}</>;
};

export default ProtectedComponent;

export interface IProtectedComponentProps {
  children: React.ReactNode;
  allowedRoles: UserRole[];
}
