import { createApiClient } from '../utils/httpClient';

import { IUserStoreRequest } from './requestModels/userStoreRequest';

export async function storeUser(request: IUserStoreRequest): Promise<void> {
  const apiClient = createApiClient();
  const url = 'api/User/Store';

  await apiClient.post(url, request);
}
