import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';

import { IErrorModel } from '../domain/base/errorModel';
import { IRequestConfiguration } from '../domain/base/requestConfiguration';
import { IResponse } from '../domain/base/response';
import { HttpError } from '../domain/errors/httpError';

import { environment } from './environment';

function mapResponse<T>(axiosResponse: AxiosResponse<T>): IResponse<T> {
  const result = {
    data: axiosResponse?.data,
    status: axiosResponse?.status,
    statusText: axiosResponse?.statusText
  };

  return result;
}

function requestInterceptor(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
  return config;
}

function requestErrorInterceptor(error: AxiosError) {
  throw error;
}

function responseInterceptor(response: AxiosResponse): AxiosResponse {
  return response;
}

function responseErrorInterceptor(error: AxiosError<IErrorModel>) {
  const config = error.config as IRequestConfiguration | undefined;

  if (config?.signal?.aborted) {
    return;
  }

  if (error.response?.status && config?.allowedErrorCodes && config.allowedErrorCodes.indexOf(error.response.status) !== -1) {
    return;
  }

  if (error.response) {
    if (error.response.data) {
      throw new HttpError(error.response.data.type, error.response.data.message, error.response.status);
    } else {
      throw new HttpError(error.response.statusText, error.response.statusText, error.response.status);
    }
  }

  throw error;
}

async function internalGet<TResponse>(
  axios: AxiosInstance,
  url: string,
  configuration?: IRequestConfiguration
): Promise<IResponse<TResponse>> {
  const response = await axios.get<TResponse>(url, { ...configuration });
  const result = mapResponse(response);

  return result;
}

async function internalPost<TResponse, TRequest>(
  axios: AxiosInstance,
  url: string,
  data?: TRequest,
  configuration?: IRequestConfiguration
): Promise<IResponse<TResponse>> {
  const response = await axios.post<TResponse>(url, data, { ...configuration });
  const result = mapResponse(response);

  return result;
}

async function internalPut<TResponse, TRequest>(
  axios: AxiosInstance,
  url: string,
  data?: TRequest,
  configuration?: IRequestConfiguration
): Promise<IResponse<TResponse>> {
  const response = await axios.put<TResponse>(url, data, { ...configuration });
  const result = mapResponse(response);

  return result;
}

async function internalPatch<TResponse, TRequest>(
  axios: AxiosInstance,
  url: string,
  data?: TRequest,
  configuration?: IRequestConfiguration
): Promise<IResponse<TResponse>> {
  const response = await axios.patch<TResponse>(url, data, { ...configuration });
  const result = mapResponse(response);

  return result;
}

async function internalDelete<TResponse>(
  axios: AxiosInstance,
  url: string,
  configuration?: IRequestConfiguration
): Promise<IResponse<TResponse>> {
  const response = await axios.delete<TResponse>(url, { ...configuration });
  const result = mapResponse(response);

  return result;
}

interface IHttpClient {
  get<TResponse>(url: string, configuration?: IRequestConfiguration): Promise<IResponse<TResponse>>;
  post<TResponse, TRequest = never>(url: string, model?: TRequest, configuration?: IRequestConfiguration): Promise<IResponse<TResponse>>;
  put<TResponse, TRequest = never>(url: string, model?: TRequest, configuration?: IRequestConfiguration): Promise<IResponse<TResponse>>;
  patch<TResponse, TRequest = never>(url: string, data?: TRequest, configuration?: IRequestConfiguration): Promise<IResponse<TResponse>>;
  delete<TResponse>(url: string, configuration?: IRequestConfiguration): Promise<IResponse<TResponse>>;
}

export function createApiClient(): IHttpClient {
  const axiosInstance = axios.create({
    baseURL: environment.apiAddress
  });

  axiosRetry(axiosInstance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

  axiosInstance.interceptors.request.use(requestInterceptor, requestErrorInterceptor);
  axiosInstance.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

  return {
    get: (url, configuration?) => internalGet(axiosInstance, url, configuration),
    post: (url, model, configuration?) => internalPost(axiosInstance, url, model, configuration),
    put: (url, model, configuration?) => internalPut(axiosInstance, url, model, configuration),
    patch: (url, data, configuration?) => internalPatch(axiosInstance, url, data, configuration),
    delete: (url, configuration?) => internalDelete(axiosInstance, url, configuration)
  };
}
