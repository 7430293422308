import { serialize } from 'object-to-formdata';

import { IWreckDocumentsCreateModel } from '../domain/models/wreckDocumentsCreateModel';
import { IWreckDocumentsDeleteModel } from '../domain/models/wreckDocumentsDeleteModel';
import { createApiClient } from '../utils/httpClient';
import { urlHelper } from '../utils/urlHelper';

import { IWreckDocumentResponse } from './responseModels/wreckDocumentResponse';

export async function getWreckDocuments(nationalContactNumber: number): Promise<IWreckDocumentResponse[]> {
  const apiClient = createApiClient();
  const url = `api/WreckDocument/${nationalContactNumber}`;

  const response = await apiClient.get<IWreckDocumentResponse[]>(url);

  return response.data;
}

export async function createWreckDocuments(model: IWreckDocumentsCreateModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/WreckDocument/${model.nationalContactNumber}`;
  const files = model.files.filter(file => file.blobFile !== undefined).map(file => file.blobFile as File);
  const formData = serialize({ files }, { noFilesWithArrayNotation: true });

  await apiClient.post<void, FormData>(url, formData);
}

export async function deleteWreckDocuments(model: IWreckDocumentsDeleteModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/WreckDocument/${model.nationalContactNumber}`;
  const requestUrl = urlHelper.buildUrl(url, { 'documentIds': model.documentIds });

  await apiClient.delete<void>(requestUrl);
}
