import { ReassignEntityType } from '../domain/enums/reassignEntityType';
import { INationalContactUpdateModel } from '../domain/models/nationalContactUpdateModel';
import { createApiClient } from '../utils/httpClient';
import { urlHelper } from '../utils/urlHelper';

import { IFindInRadiusRequest } from './requestModels/findInRadiusRequest';
import { INationalContactInRadiusResponse } from './responseModels/nationalContactInRadiusResponse';
import { INationalContactResponse } from './responseModels/nationalContactResponse';

export async function getNationalContact(nationalContactNumber: number): Promise<INationalContactResponse> {
  const apiClient = createApiClient();
  const url = `api/NationalContact/${nationalContactNumber}`;

  const response = await apiClient.get<INationalContactResponse>(url, {
    allowedErrorCodes: [404]
  });

  return response.data;
}

export async function getContactsInRadius(model: IFindInRadiusRequest): Promise<INationalContactInRadiusResponse[]> {
  const apiClient = createApiClient();
  const url = urlHelper.buildUrl('api/NationalContact/find-in-radius', model);

  const response = await apiClient.get<INationalContactInRadiusResponse[]>(url);

  return response.data;
}

export async function switchContactArchival(nationalContactNumbers: number[]) {
  const apiClient = createApiClient();
  const url = `api/NationalContact/archive`;

  const response = await apiClient.patch(url, nationalContactNumbers);

  return response;
}

export async function switchContactDeletion(nationalContactNumbers: number[]) {
  const apiClient = createApiClient();
  const url = `api/NationalContact/delete`;

  const response = await apiClient.patch(url, nationalContactNumbers);

  return response;
}

export async function updateNationalContact(model: INationalContactUpdateModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/NationalContact/${model.nationalContactNumber}`;

  await apiClient.put(url, model.request);
}

export async function searchForReassign(entityType: ReassignEntityType, term: string): Promise<number[]> {
  const apiClient = createApiClient();
  const url = `api/NationalContact/search-for-reassign?entityType=${entityType}&term=${term}`;

  const response = await apiClient.get<number[]>(url);

  return response.data;
}
