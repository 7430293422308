import { useOidc } from '@axa-fr/react-oidc';
import { css } from '@emotion/react';
import ExitIcon from '@rsuite/icons/Exit';
import UserIcon from '@rsuite/icons/legacy/User';
import { TFunction } from 'i18next';
import { FC, Ref, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GrHomeRounded } from 'react-icons/gr';
import { GrUserAdmin } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, Header, IconButton, Nav, Navbar } from 'rsuite';
import { WithAsProps } from 'rsuite/esm/@types/common';

import { Language } from '../../domain/enums/language';
import { UserRole } from '../../domain/enums/userRole';
import { primaryBackgroundColor, secondaryHeaderButtonStyle } from '../../sharedStyles';
import { urlHelper } from '../../utils/urlHelper';
import ProtectedComponent from '../authorization/protectedComponent';
import BackToRootLinkComponent from '../core/backToRootLinkComponent';

import HeaderButtonsRibbonComponent from './headerButtonsRibbonComponent';

const primaryHeaderStyle = css({
  height: '88px',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative'
});

const logoWrapper = css({
  display: 'flex',
  flex: '0 1 200px',
  justifyContent: 'center'
});

const logoStyle = css({
  width: '44px',
  height: '77px'
});

const languageBlock = css({
  position: 'absolute',
  top: '15px',
  left: '100px'
});

const languageStyle = css({
  fontStyle: 'italic'
});

const avatarContainerStyle = css({
  position: 'absolute',
  top: '30px',
  right: '50px',
  zIndex: 5
});

const secondaryHeaderStyle = css([primaryBackgroundColor], {
  padding: '0 40px',
  height: '44px'
});

const homeTitleStyle = css({ marginTop: '4px', color: 'rgb(61, 56, 56)' });

const dropdownStyle = css({
  zIndex: 8
});

const itemStyle = css({
  '&.rs-dropdown-item.rs-dropdown-item-focus': {
    color: 'black',
    backgroundColor: '#e5e5ea'
  },
  '&>.rs-dropdown-item-menu-icon': {
    marginRight: '10px'
  }
});

const AvatarComponent = (props: WithAsProps, ref: Ref<HTMLElement>) => {
  return <IconButton {...props} ref={ref} icon={<UserIcon />} />;
};
AvatarComponent.displayName = 'AvatarComponent';

//TODO ::: Restore language selection once it's approved /Renat
const HeaderComponent: FC = () => {
  const { isAuthenticated, logout } = useOidc();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const onSignOutClick = useCallback(() => {
    //Signout logic works, but worth checking https://github.com/AxaGuilDEv/react-oidc/issues/915 if it's resolved /Renat
    logout(`${window.location.origin}${urlHelper.patterns.signedOut}`);
  }, [logout]);

  const onAdminClick = useCallback(() => {
    navigate(urlHelper.routes.admin);
  }, [navigate]);

  const toDutch = useCallback(() => {
    i18n.changeLanguage(Language.Dutch);
  }, [i18n]);

  const toEnglish = useCallback(() => {
    i18n.changeLanguage(Language.English);
  }, [i18n]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderLanguageSelection = (t: TFunction) => {
    return (
      <div css={languageBlock}>
        <Button appearance="link" onClick={toDutch} css={languageStyle}>
          {t('Dutch')}
        </Button>
        <Button appearance="link" onClick={toEnglish} css={languageStyle}>
          {t('English')}
        </Button>
      </div>
    );
  };

  const tryRenderAvatar = (t: TFunction) => {
    if (isAuthenticated) {
      return (
        <div css={avatarContainerStyle}>
          <Dropdown css={dropdownStyle} renderToggle={AvatarComponent} trigger="hover" placement="bottomEnd">
            <ProtectedComponent allowedRoles={[UserRole.DataAdmin, UserRole.AppAdmin]}>
              <Dropdown.Item css={itemStyle} icon={<GrUserAdmin />} onClick={onAdminClick}>
                {t('Admin page')}
              </Dropdown.Item>
            </ProtectedComponent>
            <Dropdown.Item css={itemStyle} icon={<ExitIcon />} onClick={onSignOutClick}>
              {t('Sign out')}
            </Dropdown.Item>
          </Dropdown>
        </div>
      );
    }
  };

  return (
    <Header>
      <div css={primaryHeaderStyle}>
        <div css={logoWrapper}>
          <img css={logoStyle} src="/logo.svg" />
        </div>
        {/* {renderLanguageSelection(t)} */}
        {tryRenderAvatar(t)}
      </div>
      <Navbar css={secondaryHeaderStyle}>
        <Nav>
          <Nav.Item icon={<GrHomeRounded size={15} />} css={secondaryHeaderButtonStyle} as={BackToRootLinkComponent}>
            <b css={homeTitleStyle}>{t('Landelijk Opslagsysteem Onderwater Objecten')}</b>
          </Nav.Item>
        </Nav>
        <HeaderButtonsRibbonComponent />
      </Navbar>
    </Header>
  );
};

export default HeaderComponent;
