import { useOidc, useOidcUser } from '@axa-fr/react-oidc';
import { css } from '@emotion/react';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Content } from 'rsuite';

import LoaderComponent from '../components/core/loaderComponent';
import FooterComponent from '../components/footerComponent';
import HeaderComponent from '../components/header/headerComponent';
import RoutingComponent from '../components/routingComponent';
import { IUserStoreRequest } from '../services/requestModels/userStoreRequest';
import { contentHeight } from '../sharedStyles';
import { commonActions } from '../store/actions/commonActions';
import { AppState } from '../store/appReducer';
import { environment } from '../utils/environment';

const mainBlockStyle = css({
  width: '100%',
  height: '100%'
});

const contentStyle = css(contentHeight, {
  display: 'flex'
});

const tokenDelayInMs = environment.tokenDelayInMinutes * 60 * 1000;

const AppContainer: FC = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useOidc();
  const { oidcUser } = useOidcUser();
  const projectionsLoaded = useSelector<AppState, boolean>(p => p.commonState.projectionsLoaded);
  const userStored = useSelector<AppState, boolean>(p => p.commonState.userStored);

  useEffect(
    function requestProjections() {
      dispatch(commonActions.requestProjections());
    },
    [dispatch]
  );

  useEffect(
    function requestToken() {
      let intervalId: NodeJS.Timer | undefined = undefined;

      if (isAuthenticated) {
        const dispatchTokenRequested = () => dispatch(commonActions.tokenRequested(window.location.origin));

        //Requests token once and then schedules an interval /Renat
        dispatchTokenRequested();
        intervalId = setInterval(dispatchTokenRequested, tokenDelayInMs);
      }

      return () => clearInterval(intervalId);
    },
    [dispatch, isAuthenticated]
  );

  useEffect(
    function storeUser() {
      if (isAuthenticated && !userStored && oidcUser) {
        const request: IUserStoreRequest = {
          id: oidcUser.sub,
          email: oidcUser.email || '',
          firstName: oidcUser.given_name || '',
          lastName: oidcUser.family_name || ''
        };

        dispatch(commonActions.storeUser(request));
      }
    },
    [dispatch, isAuthenticated, oidcUser, userStored]
  );

  if (projectionsLoaded) {
    return (
      <Container css={mainBlockStyle}>
        <HeaderComponent />
        <Content css={contentStyle}>
          <RoutingComponent />
        </Content>
        <FooterComponent />
      </Container>
    );
  } else {
    return <LoaderComponent />;
  }
};

export default AppContainer;
