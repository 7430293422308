import { useOidc } from '@axa-fr/react-oidc';
import { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoaderComponent from '../components/core/loaderComponent';
import { IReferenceResponse } from '../services/responseModels/referenceResponse';
import { commonActions } from '../store/actions/commonActions';
import { AppState } from '../store/appReducer';
import { IReferencesContext, ReferencesContext } from '../utils/referencesContext';

const ReferencesContextProvider: React.FC<IReferencesContextProviderProps> = (props: IReferencesContextProviderProps) => {
  const { isAuthenticated } = useOidc();
  const dispatch = useDispatch();
  const references = useSelector<AppState, IReferenceResponse[]>(s => s.commonState.references);

  const context: IReferencesContext = useMemo(
    () => ({
      references: references
    }),
    [references]
  );

  useEffect(
    function fetchReferences() {
      if (isAuthenticated) {
        dispatch(commonActions.referencesRequested());
      }
    },
    [dispatch, isAuthenticated]
  );

  function tryRenderLoader() {
    if (isAuthenticated && references.length === 0) {
      return <LoaderComponent />;
    }
  }

  return (
    <ReferencesContext.Provider value={context}>
      {tryRenderLoader()}
      {props.children}
    </ReferencesContext.Provider>
  );
};

export default ReferencesContextProvider;

export interface IReferencesContextProviderProps {
  children: ReactElement;
}
