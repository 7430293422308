import { createReducer } from '@reduxjs/toolkit';

import { IAreaResponse } from '../../services/responseModels/areaResponse';
import { contactsOverviewActions } from '../actions/contactsOverviewActions';

export interface IContactsOverviewState {
  readonly areas: IAreaResponse[];
}

const initialState: IContactsOverviewState = {
  areas: []
};

export const contactsOverviewReducer = createReducer(initialState, builder => {
  builder.addCase(contactsOverviewActions.areasFetched, (state, action) => {
    state.areas = action.payload;
  });
});
