import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { Language } from './src/domain/enums/language';
import { environment } from './src/utils/environment';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: Language.Dutch,
    fallbackLng: Language.Dutch,
    debug: environment.isDevelopment,
    interpolation: {
      escapeValue: false
    },
    returnObjects: true
  });

export default i18n;
