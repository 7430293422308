import { createAction } from '@reduxjs/toolkit';

import { IRwsWizardState } from '../../domain/models/rwsWizardState';
import { IFindInRadiusRequest } from '../../services/requestModels/findInRadiusRequest';
import { IGisExportRequest } from '../../services/requestModels/gisExportRequest';
import { IUserStoreRequest } from '../../services/requestModels/userStoreRequest';
import { IWordExportRequest } from '../../services/requestModels/wordExportRequest';
import { INationalContactInRadiusResponse } from '../../services/responseModels/nationalContactInRadiusResponse';
import { IReferenceResponse } from '../../services/responseModels/referenceResponse';
import { ITokenResponse } from '../../services/responseModels/tokenResponse';

const requestProjections = createAction('PROJECTIONS_REQUESTED');
const projectionsLoaded = createAction('PROJECTIONS_LOADED');

const storeUser = createAction<IUserStoreRequest>('STORE_USER');
const userStored = createAction('USER_STORED');

const gisExportStarted = createAction<IGisExportRequest>('GIS_EXPORT_STARTED');
const wordExportStarted = createAction<IWordExportRequest>('WORD_EXPORT_STARTED');
const activeExportCancelled = createAction('ACTIVE_EXPORT_CANCELLED');

const exportStateChanged = createAction<boolean>('EXPORT_STATE_CHANGED');

const tokenRequested = createAction<string>('TOKEN_REQUESTED');
const tokenRetrieved = createAction<ITokenResponse>('TOKEN_RETRIEVED');

const contactsInRadiusRequested = createAction<IFindInRadiusRequest>('CONTACTS_IN_RADIUS_REQUESTED');
const contactsInRadiusRetrieved = createAction<INationalContactInRadiusResponse[]>('CONTACTS_IN_RADIUS_RETRIEVED');

const completeRwsWizard = createAction<IRwsWizardState>('COMPLETE_RWS_WIZARD');
const saveInProgressChanged = createAction<boolean>('SAVE_IN_PROGRESS_CHANGED');

const referencesRequested = createAction('REFERENCES_REQUESTED');
const referencesFetched = createAction<IReferenceResponse[]>('REFERENCES_FETCHED');

const archiveNationalContacts = createAction<number[]>('CONTACTS_ARCHIVAL_SWITCHED');
const deleteNationalContacts = createAction<number[]>('CONTACTS_DELETION_SWITCHED');

export const commonActions = {
  requestProjections,
  projectionsLoaded,
  exportStateChanged,
  gisExportStarted,
  wordExportStarted,
  tokenRequested,
  tokenRetrieved,
  activeExportCancelled,
  contactsInRadiusRequested,
  contactsInRadiusRetrieved,
  completeRwsWizard,
  saveInProgressChanged,
  referencesRequested,
  referencesFetched,
  archiveNationalContacts,
  deleteNationalContacts,
  storeUser,
  userStored
};
