import { createReducer } from '@reduxjs/toolkit';

import { IAuditLogResponse } from '../../services/responseModels/auditLogResponse';
import { adminActions } from '../actions/adminActions';

const initialAdminState: IAdminState = {
  wreckExportInProgress: false,
  auditLog: undefined,
  auditLogFetchInProgress: false
};

export interface IAdminState {
  readonly wreckExportInProgress: boolean;
  readonly auditLog?: IAuditLogResponse;
  readonly auditLogFetchInProgress: boolean;
}

export const adminReducer = createReducer(initialAdminState, builder => {
  builder.addCase(adminActions.wreckExportStateChanged, (state, action) => {
    state.wreckExportInProgress = action.payload;
  });
  builder.addCase(adminActions.auditLogFetched, (state, action) => {
    state.auditLog = action.payload;
  });
  builder.addCase(adminActions.auditLogFetchStateChanged, (state, action) => {
    state.auditLogFetchInProgress = action.payload;
  });
  builder.addCase(adminActions.adminStateCleanup, () => {
    return initialAdminState;
  });
});
