import { createContext } from 'react';

import { HeaderButtons } from '../domain/enums/headerButtonType';
import { ToolDrawerState } from '../domain/enums/toolDrawerState';

//TODO ::: Find a better name for this shit (or rework it completely) /Renat
export interface IHeaderContext {
  visibleButtons: HeaderButtons[];
  setVisibleButtons: (buttons: HeaderButtons[]) => void;
  drawerState: ToolDrawerState;
  toggleDrawerState: (state: ToolDrawerState) => void;
  mapVisible: boolean;
  setMapVisible: (checked: boolean) => void;
  tableVisible: boolean;
  setTableVisible: (checked: boolean) => void;
  searchActive: boolean;
  setSearchActive: (active: boolean) => void;
  rwsWizardVisible: boolean;
  setRwsWizardVisible: (visible: boolean) => void;
}

export const HeaderContext = createContext<IHeaderContext>({
  visibleButtons: [],
  setVisibleButtons: () => void 0,
  drawerState: ToolDrawerState.Closed,
  toggleDrawerState: () => void 0,
  mapVisible: true,
  setMapVisible: () => void 0,
  tableVisible: true,
  setTableVisible: () => void 0,
  searchActive: false,
  setSearchActive: () => void 0,
  rwsWizardVisible: false,
  setRwsWizardVisible: () => void 0
});
