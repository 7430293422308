import { serialize } from 'object-to-formdata';

import { IRwsObservationDocumentsCreateModel } from '../domain/models/rwsObservationDocumentsCreateModel';
import { IRwsObservationDocumentsDeleteModel } from '../domain/models/rwsObservationDocumentsDeleteModel';
import { createApiClient } from '../utils/httpClient';
import { urlHelper } from '../utils/urlHelper';

import { IRwsObservationDocumentResponse } from './responseModels/rwsObservationDocumentResponse';

export async function getRwsObservationDocuments(observationIds: number[]): Promise<IRwsObservationDocumentResponse[]> {
  const apiClient = createApiClient();
  const url = `api/Rws/Observations/Documents/`;
  const requestUrl = urlHelper.buildUrl(url, { 'observationIds': observationIds });

  const response = await apiClient.get<IRwsObservationDocumentResponse[]>(requestUrl);

  return response.data;
}

export async function createRwsObservationDocuments(model: IRwsObservationDocumentsCreateModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/RwsContacts/${model.rwsContactId}/Observations/${model.rwsObservationId}/Documents`;
  const files = model.files.filter(file => file.blobFile !== undefined).map(file => file.blobFile as File);
  const formData = serialize({ files }, { noFilesWithArrayNotation: true });

  await apiClient.post<void, FormData>(url, formData);
}

export async function deleteRwsObservationDocuments(model: IRwsObservationDocumentsDeleteModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/RwsContacts/${model.rwsContactId}/Observations/${model.rwsObservationId}/Documents`;
  const requestUrl = urlHelper.buildUrl(url, { 'documentIds': model.documentIds });

  await apiClient.delete<void>(requestUrl);
}
