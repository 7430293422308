import { createApiClient } from '../utils/httpClient';

import { IReferenceCreateRequest } from './requestModels/referenceCreateRequest';
import { IReferenceUpdateRequest } from './requestModels/referenceUpdateRequest';
import { IReferenceResponse } from './responseModels/referenceResponse';

export async function getReferences(): Promise<IReferenceResponse[]> {
  const apiClient = createApiClient();
  const url = 'api/Reference';

  const response = await apiClient.get<IReferenceResponse[]>(url);

  return response.data;
}

export async function createReference(request: IReferenceCreateRequest): Promise<number> {
  const apiClient = createApiClient();
  const url = 'api/Reference';

  const response = await apiClient.post<number, IReferenceCreateRequest>(url, request);

  return response.data;
}

export async function updateReference(id: number, request: IReferenceUpdateRequest): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Reference/${id}`;

  await apiClient.put<void, IReferenceUpdateRequest>(url, request);
}
