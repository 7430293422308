import { useTranslation } from 'react-i18next';
import { Loader } from 'rsuite';

import { centeredContent } from '../../sharedStyles';

const SignInComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div css={centeredContent}>
      <h3>{t('Hold on tight while we are signing you in...')}</h3>
      <Loader />
    </div>
  );
};

export default SignInComponent;
