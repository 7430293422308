import { createReducer } from '@reduxjs/toolkit';

import { ICommentResponse } from '../../services/responseModels/commentResponse';
import { IDhyObservationResponse } from '../../services/responseModels/dhyObservationResponse';
import { IEncObstructionResponse } from '../../services/responseModels/encObstructionResponse';
import { IEncWreckResponse } from '../../services/responseModels/encWreckResponse';
import { INationalContactResponse } from '../../services/responseModels/nationalContactResponse';
import { IRceContactResponse } from '../../services/responseModels/rceContactResponse';
import { IRwsContactResponse } from '../../services/responseModels/rwsContactResponse';
import { IRwsObservationDocumentResponse } from '../../services/responseModels/rwsObservationDocumentResponse';
import { IRwsObservationResponse } from '../../services/responseModels/rwsObservationResponse';
import { ISalvageInfoResponse } from '../../services/responseModels/salvageInfoResponse';
import { IWreckDocumentResponse } from '../../services/responseModels/wreckDocumentResponse';
import { IWreckInfoResponse } from '../../services/responseModels/wreckInfoResponse';
import { contactDetailsActions } from '../actions/contactDetailsActions';

const initialContactDetailsState: IContactDetailsState = {
  loaderSwitched: false,
  rwsObservations: [],
  rwsObservationDocuments: [],
  dhyObservations: [],
  wreckDocuments: [],
  contactsForReassign: []
};

export interface IContactDetailsState {
  readonly comments?: ICommentResponse[];
  readonly dhyObservations: IDhyObservationResponse[];
  readonly encWreck?: IEncWreckResponse;
  readonly encObstruction?: IEncObstructionResponse;
  readonly nationalContact?: INationalContactResponse;
  readonly rceContact?: IRceContactResponse;
  readonly rwsContact?: IRwsContactResponse;
  readonly rwsObservationDocuments: IRwsObservationDocumentResponse[];
  readonly rwsObservations: IRwsObservationResponse[];
  readonly salvageInfo?: ISalvageInfoResponse;
  readonly wreckDocuments: IWreckDocumentResponse[];
  readonly wreckInfo?: IWreckInfoResponse;
  readonly loaderSwitched: boolean;
  readonly contactsForReassign: number[];
}

export const contactDetailsReducer = createReducer(initialContactDetailsState, builder => {
  builder.addCase(contactDetailsActions.commentsFetched, (state, action) => {
    state.comments = action.payload;
  });
  builder.addCase(contactDetailsActions.dhyObservationsFetched, (state, action) => {
    state.dhyObservations = action.payload;
  });
  builder.addCase(contactDetailsActions.encWreckFetched, (state, action) => {
    state.encWreck = action.payload;
  });
  builder.addCase(contactDetailsActions.encObstructionFetched, (state, action) => {
    state.encObstruction = action.payload;
  });
  builder.addCase(contactDetailsActions.nationalContactFetched, (state, action) => {
    state.nationalContact = action.payload;
  });
  builder.addCase(contactDetailsActions.rceContactFetched, (state, action) => {
    state.rceContact = action.payload;
  });
  builder.addCase(contactDetailsActions.rwsContactFetched, (state, action) => {
    state.rwsContact = action.payload;
  });
  builder.addCase(contactDetailsActions.rwsObservationDocumentsFetched, (state, action) => {
    state.rwsObservationDocuments = action.payload;
  });
  builder.addCase(contactDetailsActions.rwsObservationsFetched, (state, action) => {
    state.rwsObservations = action.payload;
  });
  builder.addCase(contactDetailsActions.salvageInfoFetched, (state, action) => {
    state.salvageInfo = action.payload;
  });
  builder.addCase(contactDetailsActions.wreckDocumentsFetched, (state, action) => {
    state.wreckDocuments = action.payload;
  });
  builder.addCase(contactDetailsActions.wreckInfoFetched, (state, action) => {
    state.wreckInfo = action.payload;
  });
  builder.addCase(contactDetailsActions.loaderSwitched, (state, action) => {
    state.loaderSwitched = action.payload;
  });
  builder.addCase(contactDetailsActions.contactDetailsCleanUp, () => {
    return initialContactDetailsState;
  });
  builder.addCase(contactDetailsActions.switchNationalContactArchivalState, state => {
    if (state.nationalContact !== undefined) {
      state.nationalContact.isArchived = !state.nationalContact.isArchived;
    }
  });
  builder.addCase(contactDetailsActions.switchNationalContactDeletionState, state => {
    if (state.nationalContact !== undefined) {
      state.nationalContact.isDeleted = !state.nationalContact.isDeleted;
    }
  });
  builder.addCase(contactDetailsActions.salvageInfoDeleted, state => {
    state.salvageInfo = undefined;
  });
  builder.addCase(contactDetailsActions.wreckInfoDeleted, state => {
    state.wreckInfo = initialContactDetailsState.wreckInfo;
    state.wreckDocuments = initialContactDetailsState.wreckDocuments;
  });
  builder.addCase(contactDetailsActions.nationalContactUpdated, (state, action) => {
    if (state.nationalContact?.nationalContactNumber === action.payload.nationalContactNumber) {
      state.nationalContact.contactTypeId = action.payload.contactTypeReference;
    }
  });
  builder.addCase(contactDetailsActions.encObstructionDeleted, state => {
    state.encObstruction = undefined;
  });
  builder.addCase(contactDetailsActions.encWreckDeleted, state => {
    state.encWreck = undefined;
  });
  builder.addCase(contactDetailsActions.rceContactUpdated, (state, action) => {
    if (state.rceContact?.nationalContactNumber === action.payload.nationalContactNumber) {
      state.rceContact.contactAvailabilityId = action.payload.contactAvailabilityReference;
    }
  });
  builder.addCase(contactDetailsActions.dhyObservationUpdated, (state, action) => {
    const affectedObservation = state.dhyObservations?.find(observation => observation.id === action.payload.dhyObservationId);

    if (affectedObservation) {
      affectedObservation.contactAvailabilityId = action.payload.contactAvailabilityReference;
    }
  });
  builder.addCase(contactDetailsActions.searchForReassignFetched, (state, action) => {
    state.contactsForReassign = action.payload;
  });
  builder.addCase(contactDetailsActions.wreckInfoReassigned, state => {
    state.wreckInfo = undefined;
    state.wreckDocuments = [];
  });
  builder.addCase(contactDetailsActions.rceContactReassigned, state => {
    state.rceContact = undefined;
  });
  builder.addCase(contactDetailsActions.dhyObservationReassigned, (state, action) => {
    state.dhyObservations = state.dhyObservations.filter(observation => observation.id !== action.payload);
  });
});
