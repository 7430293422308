import { createApiClient } from '../utils/httpClient';

import { IAreaResponse } from './responseModels/areaResponse';

export async function getAreas(): Promise<IAreaResponse[]> {
  const apiClient = createApiClient();

  const response = await apiClient.get<IAreaResponse[]>('api/Areas');

  return response.data;
}
