import { Global } from '@emotion/react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import OidcProviderComponent from './components/authentication/oidcProviderComponent';
import ErrorBoundaryComponent from './components/errorBoundaryComponent';
import AppContainer from './containers/appContainer';
import HeaderContextProvider from './providers/headerContextProvider';
import LoadingContextProvider from './providers/loadingContextProvider';
import ReferencesContextProvider from './providers/referencesContextProvider';
import ThirdPartyLocalizationProvider from './providers/thirdPartyLocalizationProvider';
import { setupStore } from './store/appStore';
import setEsriEnvironment from './utils/esriEnviromentHelper';
import reactPlugin from './applicationInsights';
import reportWebVitals from './reportWebVitals';
import { esriOverrides } from './sharedStyles';

import 'virtual:fonts.css';
import './index.css';

import '../i18n';

setEsriEnvironment();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ErrorBoundaryComponent>
      <OidcProviderComponent>
        <AppInsightsContext.Provider value={reactPlugin}>
          <Provider store={setupStore()}>
            <BrowserRouter>
              <Global styles={esriOverrides} />
              <HeaderContextProvider>
                <ThirdPartyLocalizationProvider>
                  <ReferencesContextProvider>
                    <LoadingContextProvider>
                      <AppContainer />
                    </LoadingContextProvider>
                  </ReferencesContextProvider>
                </ThirdPartyLocalizationProvider>
              </HeaderContextProvider>
            </BrowserRouter>
          </Provider>
        </AppInsightsContext.Provider>
      </OidcProviderComponent>
    </ErrorBoundaryComponent>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
