import { setLocale } from '@arcgis/core/intl';
import { FC, ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomProvider } from 'rsuite';
import en from 'rsuite/locales/en_US';
import nl from 'rsuite/locales/nl_NL';

import { Language } from '../domain/enums/language';

const ThirdPartyLocalizationProvider: FC<IThirdPartyLocalizationProviderProps> = (props: IThirdPartyLocalizationProviderProps) => {
  const { i18n } = useTranslation();
  const rsuiteLocale = useMemo(() => (i18n.language === Language.Dutch ? nl : en), [i18n.language]);

  useEffect(() => {
    setLocale(i18n.language);
  }, [i18n.language]);

  return <CustomProvider locale={rsuiteLocale}>{props.children}</CustomProvider>;
};

export default ThirdPartyLocalizationProvider;

export interface IThirdPartyLocalizationProviderProps {
  children: ReactElement;
}
