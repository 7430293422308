import { createApiClient } from '../utils/httpClient';

import { ISalvageInfoCreateRequest } from './requestModels/salvageInfoCreateRequest';
import { ISalvageInfoUpdateRequest } from './requestModels/salvageInfoUpdateRequest';
import { ISalvageInfoResponse } from './responseModels/salvageInfoResponse';

export async function getSalvageInfo(nationalContactNumber: number): Promise<ISalvageInfoResponse> {
  const apiClient = createApiClient();
  const url = `api/Salvage/${nationalContactNumber}`;

  const response = await apiClient.get<ISalvageInfoResponse>(url, {
    allowedErrorCodes: [404]
  });

  return response.data;
}

export async function createSalvageInfo(request: ISalvageInfoCreateRequest): Promise<number> {
  const apiClient = createApiClient();
  const url = `api/Salvage`;

  const response = await apiClient.post<number, ISalvageInfoCreateRequest>(url, request);

  return response.data;
}

export async function updateSalvageInfo(request: ISalvageInfoUpdateRequest): Promise<number> {
  const apiClient = createApiClient();
  const url = `api/Salvage`;

  const response = await apiClient.put<number, ISalvageInfoUpdateRequest>(url, request);

  return response.data;
}

export async function deleteSalvageInfo(nationalContactNumber: number): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Salvage/${nationalContactNumber}`;

  await apiClient.delete(url);
}
