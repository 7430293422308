import { css } from '@emotion/react';
import { Trans, useTranslation } from 'react-i18next';

import { centeredContent } from '../../sharedStyles';

const refreshPage = () => window.location.reload();

const refreshStyle = css({
  textDecoration: 'underline',
  cursor: 'pointer'
});

const AuthenticatingErrorComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div css={centeredContent}>
      <h3>{t('An error occurred during authentication')}</h3>
      <div>
        <Trans i18nKey="ErrorSuggestions">
          Try
          <span onClick={refreshPage} css={refreshStyle}>
            refreshing your page
          </span>
          or contact us if the problem persists
        </Trans>
      </div>
    </div>
  );
};

export default AuthenticatingErrorComponent;
