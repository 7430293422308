import { createApiClient } from '../utils/httpClient';
import { urlHelper } from '../utils/urlHelper';

import { IAuditLogRequest } from './requestModels/auditLogRequest';
import { IAuditLogResponse } from './responseModels/auditLogResponse';
import { ITokenResponse } from './responseModels/tokenResponse';

async function getToken(referer: string): Promise<ITokenResponse> {
  const apiClient = createApiClient();
  const url = urlHelper.buildUrl('api/Utility/Token', { referer: referer });

  const response = await apiClient.get<ITokenResponse>(url);

  return response.data;
}

async function getAuditLog(model: IAuditLogRequest): Promise<IAuditLogResponse> {
  const apiClient = createApiClient();
  const url = urlHelper.buildUrl('api/Utility/AuditLog', model);

  const response = await apiClient.get<IAuditLogResponse>(url);

  return response.data;
}

export { getToken, getAuditLog };
