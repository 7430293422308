export class HttpError extends Error {
  body: string;
  status: number;

  constructor(message: string, body: string, status: number) {
    super(message);

    this.body = body;
    this.status = status;
  }
}
