import { TFunction } from 'i18next';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GrAdd, GrDocumentDownload, GrRevert, GrSearch, GrView } from 'react-icons/gr';
import { IconType } from 'react-icons/lib';
import { Checkbox, Nav, Tooltip, Whisper } from 'rsuite';

import { HeaderButtons } from '../../domain/enums/headerButtonType';
import { ToolDrawerState } from '../../domain/enums/toolDrawerState';
import { UserRole } from '../../domain/enums/userRole';
import { secondaryHeaderButtonStyle } from '../../sharedStyles';
import { HeaderContext } from '../../utils/headerContext';
import ProtectedComponent from '../authorization/protectedComponent';

function tryRenderExportButton(t: TFunction, visibleButtons: HeaderButtons[], onClick: () => void) {
  if (visibleButtons.includes(HeaderButtons.Export)) {
    return renderSimpleButton(t('Export'), onClick, GrDocumentDownload);
  }
}

function tryRenderSearchButton(t: TFunction, visibleButtons: HeaderButtons[], onClick: () => void) {
  if (visibleButtons.includes(HeaderButtons.Search)) {
    return renderSimpleButton(t('Search'), onClick, GrSearch);
  }
}

function tryRenderVisibilityButton(
  t: TFunction,
  visibleButtons: HeaderButtons[],
  mapVisible: boolean,
  onMapCheckboxChange: (_: unknown, checked: boolean) => void,
  tableVisible: boolean,
  onTableCheckboxChange: (_: unknown, checked: boolean) => void
) {
  if (visibleButtons.includes(HeaderButtons.Visibility)) {
    return (
      <Whisper
        placement="autoVertical"
        preventOverflow={true}
        speaker={
          <Tooltip>
            <Checkbox checked={mapVisible} onChange={onMapCheckboxChange}>
              {t('Map')}
            </Checkbox>
            <Checkbox checked={tableVisible} onChange={onTableCheckboxChange}>
              {t('Table')}
            </Checkbox>
          </Tooltip>
        }
        enterable={true}
      >
        <Nav.Item css={secondaryHeaderButtonStyle}>
          <GrView size={20} />
        </Nav.Item>
      </Whisper>
    );
  }
}

function tryRenderResetButton(t: TFunction, visibleButtons: HeaderButtons[], onClick: () => void) {
  if (visibleButtons.includes(HeaderButtons.Reset)) {
    return renderSimpleButton(t('Clear selection'), onClick, GrRevert);
  }
}

function tryRenderAddButton(t: TFunction, visibleButtons: HeaderButtons[], onClick: () => void) {
  if (visibleButtons.includes(HeaderButtons.Add)) {
    return <ProtectedComponent allowedRoles={[UserRole.DataAdmin]}>{renderSimpleButton(t('Add'), onClick, GrAdd)}</ProtectedComponent>;
  }
}

const renderSimpleButton = (tooltip: string, onClick: () => void, icon: IconType) => {
  return (
    <Whisper speaker={<Tooltip>{tooltip}</Tooltip>} placement="bottom">
      <Nav.Item css={secondaryHeaderButtonStyle} onClick={onClick}>
        {icon({ size: 20 })}
      </Nav.Item>
    </Whisper>
  );
};

const HeaderButtonsRibbonComponent: React.FC = () => {
  const {
    visibleButtons,
    mapVisible,
    tableVisible,
    toggleDrawerState,
    setMapVisible,
    setTableVisible,
    setSearchActive,
    setRwsWizardVisible
  } = useContext(HeaderContext);
  const { t } = useTranslation();

  const toggleExport = useCallback(() => toggleDrawerState(ToolDrawerState.Export), [toggleDrawerState]);

  const toggleSearch = useCallback(() => toggleDrawerState(ToolDrawerState.Search), [toggleDrawerState]);

  const onMapCheckboxChange = useCallback(
    (_: unknown, checked: boolean) => {
      setMapVisible(checked);
    },
    [setMapVisible]
  );

  const onResetClick = useCallback(() => {
    setSearchActive(false);
  }, [setSearchActive]);

  const onTableCheckboxChange = useCallback(
    (_: unknown, checked: boolean) => {
      setTableVisible(checked);
    },
    [setTableVisible]
  );

  const showRwsWizard = useCallback(() => {
    setRwsWizardVisible(true);
  }, [setRwsWizardVisible]);

  return (
    <Nav pullRight={true}>
      {tryRenderAddButton(t, visibleButtons, showRwsWizard)}
      {tryRenderVisibilityButton(t, visibleButtons, mapVisible, onMapCheckboxChange, tableVisible, onTableCheckboxChange)}
      {tryRenderExportButton(t, visibleButtons, toggleExport)}
      {tryRenderSearchButton(t, visibleButtons, toggleSearch)}
      {tryRenderResetButton(t, visibleButtons, onResetClick)}
    </Nav>
  );
};

export default HeaderButtonsRibbonComponent;
