import { all, call, takeEvery } from 'redux-saga/effects';

import { notificationHelper } from '../../utils/notificationHelper';
import { notificationActions } from '../actions/notificationActions';

function* raiseNotification(action: ReturnType<typeof notificationActions.push>) {
  yield call(notificationHelper.push, action.payload);
}

function* notificationRaisedHandler() {
  yield takeEvery(notificationActions.push, raiseNotification);
}

export function* notificationSaga(): Generator {
  yield all([notificationRaisedHandler()]);
}
