import { OidcSecure } from '@axa-fr/react-oidc';
import loadable from '@loadable/component';
import { ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';

import { urlHelper } from '../utils/urlHelper';

import LoaderComponent from './core/loaderComponent';

const options = { fallback: <LoaderComponent /> };

const ContactsOverviewContainer = loadable(
  () => import('../containers/contactsOverviewContainer' /* webpackChunkName: 'ContactsOverviewContainer' */),
  options
);

const ContactDetailsContainer = loadable(
  () => import('../containers/contactDetailsContainer' /* webpackChunkName: 'ContactDetailsContainer' */),
  options
);

const AuxiliaryInfoPagesComponent = loadable(
  () => import('./auxiliaryInfo/auxiliaryInfoPagesComponent' /* webpackChunkName: 'AuxiliaryInfoPagesComponent' */),
  options
);

const InfoComponent = loadable(() => import('./infoComponent' /* webpackChunkName: 'InfoComponent' */), options);

const SignedOutComponent = loadable(
  () => import('./authentication/signedOutComponent' /* webpackChunkName: 'SignedOutComponent' */),
  options
);

const NotFoundComponent = loadable(() => import('./notFoundComponent' /* webpackChunkName: 'NotFoundComponent' */), options);

const NotAutorizedComponent = loadable(
  () => import('./authorization/notAuthorizedComponent' /* webpackChunkName: 'NotAutorizedComponent' */),
  options
);

const LandingPageComponent = loadable(() => import('./landingPageComponent' /* webpackChunkName: 'LandingPageComponent' */), options);

const AdminContainer = loadable(() => import('../containers/adminContainer' /* webpackChunkName: 'AdminContainer' */), options);

const buildRoute = (path: string, element: ReactNode, authRequired = true) => {
  if (authRequired) {
    element = <OidcSecure>{element}</OidcSecure>;
  }

  return <Route path={path} element={element} />;
};

const RoutingComponent: React.FC = () => {
  return (
    <Routes>
      {buildRoute(urlHelper.patterns.root, <LandingPageComponent />, false)}
      {buildRoute(urlHelper.patterns.contacts, <ContactsOverviewContainer />)}
      {buildRoute(urlHelper.patterns.details, <ContactDetailsContainer />)}
      {buildRoute(urlHelper.patterns.admin, <AdminContainer />)}
      {buildRoute(urlHelper.patterns.auxiliaryInfo, <AuxiliaryInfoPagesComponent />, false)}
      {buildRoute(urlHelper.patterns.info, <InfoComponent />, false)}
      {buildRoute(urlHelper.patterns.signedOut, <SignedOutComponent />, false)}
      {buildRoute(urlHelper.patterns.notAutorized, <NotAutorizedComponent />, false)}
      {buildRoute(urlHelper.patterns.wildcard, <NotFoundComponent />, false)}
    </Routes>
  );
};

export default RoutingComponent;
