import { serialize } from 'object-to-formdata';

import { IRwsObservationReassignModel } from '../domain/models/rwsObservationReassignModel';
import { IRwsWizardState } from '../domain/models/rwsWizardState';
import { createApiClient } from '../utils/httpClient';

import { IRwsObservationCreateRequest } from './requestModels/rwsObservationCreateRequest';
import { IRwsObservationUpdateRequest } from './requestModels/rwsObservationUpdateRequest';
import { IRwsWizardCompleteRequest } from './requestModels/rwsWizardCompleteRequest';
import { IRwsObservationResponse } from './responseModels/rwsObservationResponse';

export async function getRwsObservations(rwsContactId: number): Promise<IRwsObservationResponse[]> {
  const apiClient = createApiClient();
  const url = `api/Rws/Observations/${rwsContactId}`;

  const response = await apiClient.get<IRwsObservationResponse[]>(url);

  return response.data;
}

export async function completeWizard(state: IRwsWizardState): Promise<number> {
  const apiClient = createApiClient();
  const url = 'api/Rws/CompleteWizard';

  const model: IRwsWizardCompleteRequest = {
    positionStep: {
      projection: state.position.projection,
      easting: state.position.easting,
      northing: state.position.northing,
      selectedNcn: state.position.selectedNcn,
      selectedRwsNumber: state.position.selectedRwsNumber
    },
    observationStep: {
      dataAcquisitionAuthority: state.observation.dataAcquisitionAuthority,
      dataManagementAuthority: state.observation.dataManagementAuthority,
      sonarType: state.observation.sonarType,
      positioning: state.observation.positioning,
      r95: state.observation.r95,
      subseaPositioning: state.observation.subseaPositioning,
      verticalDatum: state.observation.verticalDatum,
      prsCode: state.observation.prsCode,
      vessel: state.observation.vessel,
      description: state.observation.description,
      surveyDate: state.observation.surveyDate,
      frequency: state.observation.frequency,
      range: state.observation.range,
      width: state.observation.width,
      height: state.observation.height,
      length: state.observation.length,
      depth: state.observation.depth
    },
    attachmentsStep: {
      files: state.attachments.files.filter(file => file.blobFile !== undefined).map(file => file.blobFile as File)
    },
    nationalContactStep: {
      contactType: state.nationalContact.contactType
    },
    rwsContactStep: {
      contactAvailability: state.rwsContact.contactAvailability
    }
  };

  const formData = serialize(model, { dotsForObjectNotation: true, noFilesWithArrayNotation: true });

  const response = await apiClient.post<number, FormData>(url, formData);

  return response.data;
}

export async function createRwsObservation(rwsContactId: number, model: IRwsObservationCreateRequest): Promise<number> {
  const apiClient = createApiClient();
  const url = `api/RwsContacts/${rwsContactId}/Observations/`;

  const response = await apiClient.post<number, IRwsObservationCreateRequest>(url, model);

  return response.data;
}

export async function updateRwsObservation(
  rwsContactId: number,
  rwsObservationId: number,
  model: IRwsObservationUpdateRequest
): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/RwsContacts/${rwsContactId}/Observations/${rwsObservationId}`;

  await apiClient.put(url, model);
}

export async function deleteRwsObservation(rwsContactId: number, rwsObservationId: number): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/RwsContacts/${rwsContactId}/Observations/${rwsObservationId}`;

  await apiClient.delete(url);
}

export async function reassignRwsObservation(model: IRwsObservationReassignModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/RwsContacts/${model.rwsContactId}/Observations/${model.rwsObservationId}/Reassign`;

  await apiClient.patch(url, model.request);
}
