import { createApiClient } from '../utils/httpClient';

import { IEncObstructionCreateRequest } from './requestModels/encObstructionCreateRequest';
import { IEncObstructionUpdateRequest } from './requestModels/encObstructionUpdateRequest';
import { IEncObstructionResponse } from './responseModels/encObstructionResponse';

export async function getEncObstruction(nationalContactNumber: number): Promise<IEncObstructionResponse> {
  const apiClient = createApiClient();
  const url = `api/EncObstruction/${nationalContactNumber}`;

  const response = await apiClient.get<IEncObstructionResponse>(url, {
    allowedErrorCodes: [404]
  });

  return response.data;
}

export async function createEncObstruction(request: IEncObstructionCreateRequest): Promise<number> {
  const apiClient = createApiClient();
  const url = `api/EncObstruction`;

  const response = await apiClient.post<number, IEncObstructionCreateRequest>(url, request);

  return response.data;
}

export async function updateEncObstruction(request: IEncObstructionUpdateRequest): Promise<number> {
  const apiClient = createApiClient();
  const url = `api/EncObstruction`;

  const response = await apiClient.put<number, IEncObstructionUpdateRequest>(url, request);

  return response.data;
}

export async function deleteEncObstruction(nationalContactNumber: number): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/EncObstruction/${nationalContactNumber}`;

  await apiClient.delete(url);
}
