import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import i18n from '../../i18n';
import { notificationHelper } from '../utils/notificationHelper';

import { commonActions } from './actions/commonActions';
import { contactDetailsActions } from './actions/contactDetailsActions';
import { notificationActions } from './actions/notificationActions';
import { appReducer, AppState } from './appReducer';
import appSaga from './appSaga';

function handleUnexpectedErrors(error: Error) {
  notificationHelper.push({
    type: 'error',
    title: i18n.t('An unexpected error seems to have occurred'),
    body: `${error.name}: ${error.message}`
  });
}

export function setupStore(preloadedState?: PreloadedState<AppState>) {
  const sagaMiddleware = createSagaMiddleware({
    onError: handleUnexpectedErrors
  });

  const store = configureStore({
    reducer: appReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredActions: [
            notificationActions.push.toString(),
            commonActions.completeRwsWizard.toString(),
            contactDetailsActions.rwsObservationDocumentsCreate.toString()
          ]
        }
      }).concat(sagaMiddleware),
    preloadedState: preloadedState
  });

  sagaMiddleware.run(appSaga);

  return store;
}

export type AppStore = ReturnType<typeof setupStore>;
