import { createApiClient } from '../utils/httpClient';

import { IEncWreckCreateRequest } from './requestModels/encWreckCreateRequest';
import { IEncWreckUpdateRequest } from './requestModels/encWreckUpdateRequest';
import { IEncWreckResponse } from './responseModels/encWreckResponse';

export async function getEncWreck(nationalContactNumber: number): Promise<IEncWreckResponse> {
  const apiClient = createApiClient();
  const url = `api/EncWreck/${nationalContactNumber}`;

  const response = await apiClient.get<IEncWreckResponse>(url, {
    allowedErrorCodes: [404]
  });

  return response.data;
}

export async function createEncWreck(request: IEncWreckCreateRequest): Promise<number> {
  const apiClient = createApiClient();
  const url = `api/EncWreck`;

  const response = await apiClient.post<number, IEncWreckCreateRequest>(url, request);

  return response.data;
}

export async function updateEncWreck(request: IEncWreckUpdateRequest): Promise<number> {
  const apiClient = createApiClient();
  const url = `api/EncWreck`;

  const response = await apiClient.put<number, IEncWreckUpdateRequest>(url, request);

  return response.data;
}

export async function deleteEncWreck(nationalContactNumber: number): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/EncWreck/${nationalContactNumber}`;

  await apiClient.delete(url);
}
