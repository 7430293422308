import { useTranslation } from 'react-i18next';

import { centeredContent } from '../../sharedStyles';

const ServiceWorkerNotSupportedComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div css={centeredContent}>
      <h3>{t('Unable to authenticate on this browser')}</h3>
      {t('Your browser is not secure enough to make authentication work. Try updating your browser or use a newer browser')}
    </div>
  );
};

export default ServiceWorkerNotSupportedComponent;
