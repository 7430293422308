import { all, call, put, takeLatest } from 'redux-saga/effects';

import i18n from '../../../i18n';
import { getAreas } from '../../services/areasService';
import { IAreaResponse } from '../../services/responseModels/areaResponse';
import { contactsOverviewActions } from '../actions/contactsOverviewActions';
import { notificationActions } from '../actions/notificationActions';

function* getAreasApiCall() {
  try {
    const areas: IAreaResponse[] = yield call(getAreas);
    yield put(contactsOverviewActions.areasFetched(areas));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve areas') }));
  }
}

function* getAreasHandler() {
  yield takeLatest(contactsOverviewActions.areasRequested, getAreasApiCall);
}

export function* contactsOverviewSaga(): Generator {
  yield all([getAreasHandler()]);
}
