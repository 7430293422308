import { IRceContactUpdateModel } from '../domain/models/rceContactUpdateModel';
import { createApiClient } from '../utils/httpClient';

import { IRceContactReassignRequest } from './requestModels/rceContactReassignRequest';
import { IRceContactResponse } from './responseModels/rceContactResponse';

export async function getRceContact(nationalContactNumber: number): Promise<IRceContactResponse> {
  const apiClient = createApiClient();
  const url = `api/Rce/${nationalContactNumber}`;

  const response = await apiClient.get<IRceContactResponse>(url, {
    allowedErrorCodes: [404]
  });

  return response.data;
}

export async function updateRceContact(model: IRceContactUpdateModel): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Rce/${model.nationalContactNumber}`;

  await apiClient.put(url, model.request);
}

export async function reassignRceContact(model: IRceContactReassignRequest): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Rce/Reassign`;

  await apiClient.patch(url, model);
}
