import { createAction } from '@reduxjs/toolkit';

import { IDhyObservationReassignModel } from '../../domain/models/dhyObservationReassignModel';
import { IDhyObservationUpdatedModel, IDhyObservationUpdateModel } from '../../domain/models/dhyObservationUpdateModel';
import { INationalContactUpdatedModel, INationalContactUpdateModel } from '../../domain/models/nationalContactUpdateModel';
import { IRceContactUpdatedModel, IRceContactUpdateModel } from '../../domain/models/rceContactUpdateModel';
import { IRwsContactDeleteModel } from '../../domain/models/rwsContactDeleteModel';
import { IRwsObservationCreateModel } from '../../domain/models/rwsObservationCreateModel';
import { IRwsObservationDeleteModel } from '../../domain/models/rwsObservationDeleteModel';
import { IRwsObservationDocumentsCreateModel } from '../../domain/models/rwsObservationDocumentsCreateModel';
import { IRwsObservationDocumentsDeleteModel } from '../../domain/models/rwsObservationDocumentsDeleteModel';
import { IRwsObservationReassignModel } from '../../domain/models/rwsObservationReassignModel';
import { IRwsObservationUpdateModel } from '../../domain/models/rwsObservationUpdateModel';
import { ISearchForReassignModel } from '../../domain/models/searchForReassignModel';
import { IWreckDocumentsCreateModel } from '../../domain/models/wreckDocumentsCreateModel';
import { IWreckDocumentsDeleteModel } from '../../domain/models/wreckDocumentsDeleteModel';
import { ICommentCreateRequest } from '../../services/requestModels/commentCreateRequest';
import { IEncObstructionCreateRequest } from '../../services/requestModels/encObstructionCreateRequest';
import { IEncObstructionUpdateRequest } from '../../services/requestModels/encObstructionUpdateRequest';
import { IEncWreckCreateRequest } from '../../services/requestModels/encWreckCreateRequest';
import { IEncWreckUpdateRequest } from '../../services/requestModels/encWreckUpdateRequest';
import { IRceContactReassignRequest } from '../../services/requestModels/rceContactReassignRequest';
import { IRwsContactReassignRequest } from '../../services/requestModels/rwsContactReassignRequest';
import { IRwsContactUpdateRequest } from '../../services/requestModels/rwsContactUpdateRequest';
import { ISalvageInfoCreateRequest } from '../../services/requestModels/salvageInfoCreateRequest';
import { ISalvageInfoUpdateRequest } from '../../services/requestModels/salvageInfoUpdateRequest';
import { IWreckInfoCreateRequest } from '../../services/requestModels/wreckInfoCreateRequest';
import { IWreckInfoReassignRequest } from '../../services/requestModels/wreckInfoReassignRequest';
import { IWreckInfoUpdateRequest } from '../../services/requestModels/wreckInfoUpdateRequest';
import { ICommentResponse } from '../../services/responseModels/commentResponse';
import { IDhyObservationResponse } from '../../services/responseModels/dhyObservationResponse';
import { IEncObstructionResponse } from '../../services/responseModels/encObstructionResponse';
import { IEncWreckResponse } from '../../services/responseModels/encWreckResponse';
import { INationalContactResponse } from '../../services/responseModels/nationalContactResponse';
import { IRceContactResponse } from '../../services/responseModels/rceContactResponse';
import { IRwsContactResponse } from '../../services/responseModels/rwsContactResponse';
import { IRwsObservationDocumentResponse } from '../../services/responseModels/rwsObservationDocumentResponse';
import { IRwsObservationResponse } from '../../services/responseModels/rwsObservationResponse';
import { ISalvageInfoResponse } from '../../services/responseModels/salvageInfoResponse';
import { IWreckDocumentResponse } from '../../services/responseModels/wreckDocumentResponse';
import { IWreckInfoResponse } from '../../services/responseModels/wreckInfoResponse';

const contactDetailsCleanUp = createAction('CONTACT_DETAILS_CLEANUP');

const commentsRequested = createAction<number>('COMMENTS_REQUESTED');
const commentsFetched = createAction<ICommentResponse[]>('COMMENTS_FETCHED');
const commentCreate = createAction<ICommentCreateRequest>('COMMENT_CREATE');

const dhyObservationsRequested = createAction<number>('DHY_OBSERVATIONS_REQUESTED');
const dhyObservationsFetched = createAction<IDhyObservationResponse[]>('DHY_OBSERVATIONS_FETCHED');
const dhyObservationUpdate = createAction<IDhyObservationUpdateModel>('DHY_OBSERVATION_UPDATE');
const dhyObservationUpdated = createAction<IDhyObservationUpdatedModel>('DHY_OBSERVATION_UPDATED');
const dhyObservationReassign = createAction<IDhyObservationReassignModel>('DHY_OBSERVATION_REASSIGN');
const dhyObservationReassigned = createAction<number>('DHY_OBSERVATION_REASSIGNED');

const encObstructionRequested = createAction<number>('ENC_OBSTRUCTION_REQUESTED');
const encObstructionFetched = createAction<IEncObstructionResponse>('ENC_OBSTRUCTION_FETCHED');
const encObstructionCreate = createAction<IEncObstructionCreateRequest>('ENC_OBSTRUCTION_CREATE');
const encObstructionUpdate = createAction<IEncObstructionUpdateRequest>('ENC_OBSTRUCTION_UPDATE');
const encObstructionDelete = createAction<number>('ENC_OBSTRUCTION_DELETE');
const encObstructionDeleted = createAction('ENC_OBSTRUCTION_DELETED');

const encWreckRequested = createAction<number>('ENC_WRECK_REQUESTED');
const encWreckFetched = createAction<IEncWreckResponse>('ENC_WRECK_FETCHED');
const encWreckCreate = createAction<IEncWreckCreateRequest>('ENC_WRECK_CREATE');
const encWreckUpdate = createAction<IEncWreckUpdateRequest>('ENC_WRECK_UPDATE');
const encWreckDelete = createAction<number>('ENC_WRECK_DELETE');
const encWreckDeleted = createAction('ENC_WRECK_DELETED');

const nationalContactRequested = createAction<number>('NATIONAL_CONTACT_REQUESTED');
const nationalContactFetched = createAction<INationalContactResponse>('NATIONAL_CONTACT_FETCHED');
const nationalContactTreeRequested = createAction<number>('NATIONAL_CONTACT_TREE_REQUESTED');
const switchNationalContactArchivalState = createAction('NATIONAL_CONTACT_ARCHIVED');
const switchNationalContactDeletionState = createAction('NATIONAL_CONTACT_DELETED');
const nationalContactUpdate = createAction<INationalContactUpdateModel>('NATIONAL_CONTACT_UPDATE');
const nationalContactUpdated = createAction<INationalContactUpdatedModel>('NATIONAL_CONTACT_UPDATED');

const searchForReassignRequested = createAction<ISearchForReassignModel>('SEARCH_FOR_REASSIGN_REQUESTED');
const searchForReassignFetched = createAction<number[]>('SEARCH_FOR_REASSIGN_FETCHED');

const rceContactRequested = createAction<number>('RCE_CONTACT_REQUESTED');
const rceContactFetched = createAction<IRceContactResponse>('RCE_CONTACT_FETCHED');
const rceContactUpdate = createAction<IRceContactUpdateModel>('RCE_CONTACT_UPDATE');
const rceContactUpdated = createAction<IRceContactUpdatedModel>('RCE_CONTACT_UPDATED');
const rceContactReassign = createAction<IRceContactReassignRequest>('RCE_CONTACT_REASSIGN');
const rceContactReassigned = createAction('RCE_CONTACT_REASSIGNED');

const rwsContactRequested = createAction<number>('RWS_CONTACT_REQUESTED');
const rwsContactFetched = createAction<IRwsContactResponse>('RWS_CONTACT_FETCHED');
const rwsContactUpdate = createAction<IRwsContactUpdateRequest>('RWS_CONTACT_UPDATE');
const rwsContactDelete = createAction<IRwsContactDeleteModel>('RWS_CONTACT_DELETE');
const rwsContactReassign = createAction<IRwsContactReassignRequest>('RWS_CONTACT_REASSIGN');

const rwsObservationDocumentsRequested = createAction<number[]>('RWS_OBSERVATION_DOCUMENTS_REQUESTED');
const rwsObservationDocumentsFetched = createAction<IRwsObservationDocumentResponse[]>('RWS_OBSERVATION_DOCUMENTS_FETCHED');
const rwsObservationDocumentsCreate = createAction<IRwsObservationDocumentsCreateModel>('RWS_OBSERVATION_DOCUMENTS_CREATE');
const rwsObservationDocumentsDelete = createAction<IRwsObservationDocumentsDeleteModel>('RWS_OBSERVATION_DOCUMENTS_DELETE');

const rwsObservationsRequested = createAction<number>('RWS_OBSERVATIONS_REQUESTED');
const rwsObservationsFetched = createAction<IRwsObservationResponse[]>('RWS_OBSERVATIONS_FETCHED');
const rwsObservationCreate = createAction<IRwsObservationCreateModel>('RWS_OBSERVATIONS_CREATE');
const rwsObservationUpdate = createAction<IRwsObservationUpdateModel>('RWS_OBSERVATIONS_UPDATE');
const rwsObservationDelete = createAction<IRwsObservationDeleteModel>('RWS_OBSERVATIONS_DELETE');
const rwsObservationReassign = createAction<IRwsObservationReassignModel>('RWS_OBSERVATION_REASSIGN');

const salvageInfoRequested = createAction<number>('SALVAGE_INFO_REQUESTED');
const salvageInfoFetched = createAction<ISalvageInfoResponse>('SALVAGE_INFO_FETCHED');
const salvageInfoCreate = createAction<ISalvageInfoCreateRequest>('SALVAGE_INFO_CREATE');
const salvageInfoUpdate = createAction<ISalvageInfoUpdateRequest>('SALVAGE_INFO_UPDATE');
const salvageInfoDelete = createAction<number>('SALVAGE_INFO_DELETE');
const salvageInfoDeleted = createAction('SALVAGE_INFO_DELETED');

const wreckDocumentsRequested = createAction<number>('WRECK_DOCUMENTS_REQUESTED');
const wreckDocumentsFetched = createAction<IWreckDocumentResponse[]>('WRECK_DOCUMENTS_FETCHED');
const wreckDocumentsCreate = createAction<IWreckDocumentsCreateModel>('WRECK_DOCUMENTS_CREATE');
const wreckDocumentsDelete = createAction<IWreckDocumentsDeleteModel>('WRECK_DOCUMENTS_DELETE');

const wreckInfoRequested = createAction<number>('WRECK_INFO_REQUESTED');
const wreckInfoFetched = createAction<IWreckInfoResponse>('WRECK_INFO_FETCHED');
const wreckInfoCreate = createAction<IWreckInfoCreateRequest>('WRECK_INFO_CREATE');
const wreckInfoUpdate = createAction<IWreckInfoUpdateRequest>('WRECK_INFO_UPDATE');
const wreckInfoDelete = createAction<number>('WRECK_INFO_DELETE');
const wreckInfoDeleted = createAction('WRECK_INFO_DELETED');
const wreckInfoReassign = createAction<IWreckInfoReassignRequest>('WRECK_INFO_REASSIGN');
const wreckInfoReassigned = createAction('WRECK_INFO_REASSIGNED');

const loaderSwitched = createAction<boolean>('LOADER_SWITCHED');

export const contactDetailsActions = {
  contactDetailsCleanUp,
  commentsRequested,
  commentsFetched,
  commentCreate,
  dhyObservationsRequested,
  dhyObservationsFetched,
  dhyObservationUpdate,
  dhyObservationUpdated,
  encObstructionRequested,
  encObstructionFetched,
  encObstructionCreate,
  encObstructionUpdate,
  encObstructionDelete,
  encObstructionDeleted,
  encWreckRequested,
  encWreckFetched,
  encWreckCreate,
  encWreckUpdate,
  encWreckDelete,
  encWreckDeleted,
  nationalContactRequested,
  nationalContactFetched,
  switchNationalContactArchivalState,
  switchNationalContactDeletionState,
  nationalContactUpdate,
  nationalContactUpdated,
  searchForReassignRequested,
  searchForReassignFetched,
  rceContactRequested,
  rceContactFetched,
  rceContactUpdate,
  rceContactUpdated,
  rwsContactRequested,
  rwsContactFetched,
  rwsContactUpdate,
  rwsContactDelete,
  rwsObservationDocumentsRequested,
  rwsObservationDocumentsFetched,
  rwsObservationDocumentsCreate,
  rwsObservationDocumentsDelete,
  rwsObservationsRequested,
  rwsObservationsFetched,
  salvageInfoRequested,
  salvageInfoFetched,
  wreckDocumentsRequested,
  wreckDocumentsFetched,
  wreckDocumentsCreate,
  wreckDocumentsDelete,
  wreckInfoRequested,
  wreckInfoFetched,
  loaderSwitched,
  salvageInfoCreate,
  salvageInfoUpdate,
  salvageInfoDelete,
  salvageInfoDeleted,
  wreckInfoCreate,
  wreckInfoUpdate,
  wreckInfoDelete,
  wreckInfoDeleted,
  rwsObservationCreate,
  rwsObservationUpdate,
  rwsObservationDelete,
  nationalContactTreeRequested,
  wreckInfoReassign,
  wreckInfoReassigned,
  rceContactReassign,
  rceContactReassigned,
  dhyObservationReassign,
  dhyObservationReassigned,
  rwsObservationReassign,
  rwsContactReassign
};
