import { createApiClient } from '../utils/httpClient';

import { IWreckInfoCreateRequest } from './requestModels/wreckInfoCreateRequest';
import { IWreckInfoReassignRequest } from './requestModels/wreckInfoReassignRequest';
import { IWreckInfoUpdateRequest } from './requestModels/wreckInfoUpdateRequest';
import { IWreckInfoResponse } from './responseModels/wreckInfoResponse';

export async function getWreckInfo(nationalContactNumber: number): Promise<IWreckInfoResponse> {
  const apiClient = createApiClient();
  const url = `api/Wreck/${nationalContactNumber}`;

  const response = await apiClient.get<IWreckInfoResponse>(url, { allowedErrorCodes: [404] });

  return response.data;
}

export async function createWreckInfo(request: IWreckInfoCreateRequest): Promise<number> {
  const apiClient = createApiClient();
  const url = `api/Wreck`;

  const response = await apiClient.post<number, IWreckInfoCreateRequest>(url, request);

  return response.data;
}

export async function updateWreckInfo(request: IWreckInfoUpdateRequest): Promise<number> {
  const apiClient = createApiClient();
  const url = `api/Wreck`;

  const response = await apiClient.put<number, IWreckInfoUpdateRequest>(url, request);

  return response.data;
}

export async function deleteWreckInfo(nationalContactNumber: number): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Wreck/${nationalContactNumber}`;

  await apiClient.delete(url);
}

export async function reassignWreckInfo(model: IWreckInfoReassignRequest): Promise<void> {
  const apiClient = createApiClient();
  const url = `api/Wreck/Reassign`;

  await apiClient.patch(url, model);
}
