/* eslint-disable @typescript-eslint/naming-convention */
//Does not cover all existing reference types, only the ones used in the application /Renat
export enum ReferenceType {
  DataAcquisitionAuthority = 'DataAquisitionAuthorityID', //TODO: Fix typo in database /Renat
  DataManagementAuthority = 'DataManagementAuthorityID',
  Positioning = 'PositioningID',
  VerticalDatum = 'VerticalDatumID',
  SonarType = 'SonarTypeID',
  SubseaPositioning = 'SubseaPositioningID',
  ContactType = 'ContactTypeID',
  ContactAvailability = 'ContactAvailabilityID',
  SalvageMethod = 'SalvageMethodID',
  ObjectType = 'ObjectType',
  WreckType = 'typewk',
  Material = 'Material',
  Century = 'Century',
  CompetentAuthority = 'CompetentAuthority',
  TerrainOwner = 'TerrainOwner',
  LegalStatus = 'LegalStatus',
  Country = 'Country',
  Condition = 'Condition',
  DegradationStatus = 'DegradationStatus',
  Threat = 'Threat',
  ArchaeologicalValue = 'ArchaeologicalValue',
  Salinity = 'Salinity',
  Current = 'Current',

  CATOBS = 'CATOBS',
  TECSOU = 'TECSOU',
  STATUS = 'STATUS',
  CONDTN = 'CONDTN',
  NATCON = 'NATCON',
  NATQUA = 'NATQUA',
  NATSUR = 'NATSUR',
  PRODCT = 'PRODCT',
  QUASOU = 'QUASOU',
  EXPSOU = 'Expsou',
  WATLEV = 'WATLEV',
  CATWRK = 'CATWRK',
  CONRAD = 'CONRAD',
  CONVIS = 'CONVIS'
}
