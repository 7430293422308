import { css } from '@emotion/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'rsuite';

import { urlHelper } from '../../../utils/urlHelper';

const wrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: '10px'
});

const RwsWizardNotificationComponent: React.FC<IRwsWizardNotificationComponentProps> = (props: IRwsWizardNotificationComponentProps) => {
  const { t } = useTranslation();

  const openContact = useCallback(() => {
    window.open(urlHelper.routes.details(props.ncn), '_blank');
  }, [props.ncn]);

  return (
    <div css={wrapperStyle}>
      {t('Do you want to open the observation?')}
      <Button onClick={openContact} color="green" appearance="primary">
        {t('Open')}
      </Button>
    </div>
  );
};

export default RwsWizardNotificationComponent;

export interface IRwsWizardNotificationComponentProps {
  ncn: number;
}
