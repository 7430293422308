import { combineReducers } from '@reduxjs/toolkit';

import { adminReducer } from './reducers/adminSlice';
import { commonReducer } from './reducers/commonSlice';
import { contactDetailsReducer } from './reducers/contactDetailsSlice';
import { contactsOverviewReducer } from './reducers/contactsOverviewSlice';
import { loadingReducer } from './reducers/loadingSlice';

export const appReducer = combineReducers({
  contactDetailsState: contactDetailsReducer,
  contactsOverviewState: contactsOverviewReducer,
  commonState: commonReducer,
  adminState: adminReducer,
  loadingSlice: loadingReducer
});

export type AppState = ReturnType<typeof appReducer>;
