import { all, apply, call, cancelled, put, race, take, takeEvery, takeLatest } from 'redux-saga/effects';

import i18n from '../../../i18n';
import { createWreckExport } from '../../services/exportService';
import { createReference, updateReference } from '../../services/referenceService';
import { IAuditLogResponse } from '../../services/responseModels/auditLogResponse';
import { getAuditLog } from '../../services/utilityService';
import { adminActions } from '../actions/adminActions';
import { commonActions } from '../actions/commonActions';
import { loadingActions } from '../actions/loadingActions';
import { notificationActions } from '../actions/notificationActions';

function* exportWreckApiCall(action: ReturnType<typeof adminActions.wreckExportStarted>) {
  const abortController = new AbortController();

  try {
    yield put(adminActions.wreckExportStateChanged(true));
    yield call(createWreckExport, action.payload, abortController.signal);
  } catch {
    yield put(notificationActions.push({ type: 'error', body: i18n.t('Failed to export data'), title: i18n.t('Failed') }));
  } finally {
    const wasCancelled: boolean = yield cancelled();

    if (wasCancelled) {
      yield apply(abortController, abortController.abort, []);
      yield put(notificationActions.push({ type: 'warning', title: i18n.t('Export cancelled') }));
    }

    yield put(adminActions.wreckExportStateChanged(false));
  }
}

function* getAuditLogApiCall(action: ReturnType<typeof adminActions.auditLogRequested>) {
  try {
    yield put(adminActions.auditLogFetchStateChanged(true));
    const auditLog: IAuditLogResponse = yield call(getAuditLog, action.payload);
    yield put(adminActions.auditLogFetched(auditLog));
    yield put(adminActions.auditLogFetchStateChanged(false));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve audit logs') }));
  }
}

function* referenceCreateApiCall(action: ReturnType<typeof adminActions.referenceCreate>) {
  try {
    yield put(loadingActions.enableLoading(action.payload.loadingKey));
    yield call(createReference, action.payload.request);
    yield put(commonActions.referencesRequested());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to save reference') }));
  } finally {
    yield put(loadingActions.disableLoading(action.payload.loadingKey));
  }
}

function* referenceUpdateApiCall(action: ReturnType<typeof adminActions.referenceUpdate>) {
  try {
    yield put(loadingActions.enableLoading(action.payload.loadingKey));
    yield call(updateReference, ...[action.payload.id, action.payload.request]);
    yield put(commonActions.referencesRequested());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed to save reference') }));
  } finally {
    yield put(loadingActions.disableLoading(action.payload.loadingKey));
  }
}

function* tryExportWrecks(action: ReturnType<typeof adminActions.wreckExportStarted>) {
  yield race([call(exportWreckApiCall, action), take(adminActions.wreckExportCancelled)]);
}

function* wreckExportStartedHandler() {
  yield takeLatest(adminActions.wreckExportStarted, tryExportWrecks);
}

function* getAuditLogHandler() {
  yield takeLatest(adminActions.auditLogRequested, getAuditLogApiCall);
}

function* referenceCreateHandler() {
  yield takeLatest(adminActions.referenceCreate, referenceCreateApiCall);
}

function* referenceUpdateHandler() {
  yield takeEvery(adminActions.referenceUpdate, referenceUpdateApiCall);
}

export function* adminSaga(): Generator {
  yield all([wreckExportStartedHandler(), getAuditLogHandler(), referenceCreateHandler(), referenceUpdateHandler()]);
}
