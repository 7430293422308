import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import i18n from '../../../i18n';
import { ReferenceType } from '../../domain/enums/referenceType';
import { createComment, getComments } from '../../services/commentService';
import { getDhyObservations, reassignDhyObservation, updateDhyObservation } from '../../services/dhyObservationService';
import { createEncObstruction, deleteEncObstruction, getEncObstruction, updateEncObstruction } from '../../services/encObstructionService';
import { createEncWreck, deleteEncWreck, getEncWreck, updateEncWreck } from '../../services/encWreckService';
import { getNationalContact, searchForReassign, updateNationalContact } from '../../services/nationalContactService';
import { getRceContact, reassignRceContact, updateRceContact } from '../../services/rceContactService';
import { ICommentResponse } from '../../services/responseModels/commentResponse';
import { IDhyObservationResponse } from '../../services/responseModels/dhyObservationResponse';
import { IEncObstructionResponse } from '../../services/responseModels/encObstructionResponse';
import { IEncWreckResponse } from '../../services/responseModels/encWreckResponse';
import { INationalContactResponse } from '../../services/responseModels/nationalContactResponse';
import { IRceContactResponse } from '../../services/responseModels/rceContactResponse';
import { IReferenceResponse } from '../../services/responseModels/referenceResponse';
import { IRwsContactResponse } from '../../services/responseModels/rwsContactResponse';
import { IRwsObservationDocumentResponse } from '../../services/responseModels/rwsObservationDocumentResponse';
import { IRwsObservationResponse } from '../../services/responseModels/rwsObservationResponse';
import { ISalvageInfoResponse } from '../../services/responseModels/salvageInfoResponse';
import { IWreckDocumentResponse } from '../../services/responseModels/wreckDocumentResponse';
import { IWreckInfoResponse } from '../../services/responseModels/wreckInfoResponse';
import { deleteRwsContact, getRwsContact, reassignRwsContact, updateRwsContact } from '../../services/rwsContactService';
import {
  createRwsObservationDocuments,
  deleteRwsObservationDocuments,
  getRwsObservationDocuments
} from '../../services/rwsObservationDocumentService';
import {
  createRwsObservation,
  deleteRwsObservation,
  getRwsObservations,
  reassignRwsObservation,
  updateRwsObservation
} from '../../services/rwsObservationService';
import { createSalvageInfo, deleteSalvageInfo, getSalvageInfo, updateSalvageInfo } from '../../services/salvageInfoService';
import { createWreckDocuments, deleteWreckDocuments, getWreckDocuments } from '../../services/wreckDocumentService';
import { createWreckInfo, deleteWreckInfo, getWreckInfo, reassignWreckInfo, updateWreckInfo } from '../../services/wreckInfoService';
import { commonActions } from '../actions/commonActions';
import { contactDetailsActions } from '../actions/contactDetailsActions';
import { loadingActions } from '../actions/loadingActions';
import { notificationActions } from '../actions/notificationActions';
import { AppState } from '../appReducer';

function* switchToLoading(isLoading: boolean) {
  yield put(contactDetailsActions.loaderSwitched(isLoading));
}

function* getCommentsApiCall(action: ReturnType<typeof contactDetailsActions.commentsRequested>) {
  try {
    // TODO - Bug:3580 - Change when string of comment entityId to Integer
    yield switchToLoading(true);
    const comments: ICommentResponse[] = yield call(getComments, action.payload);
    yield put(contactDetailsActions.commentsFetched(comments));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve comments') }));
  } finally {
    yield switchToLoading(false);
  }
}

function* commentCreateApiCall(action: ReturnType<typeof contactDetailsActions.commentCreate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(createComment, action.payload);
    yield put(contactDetailsActions.commentsRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save comment') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* getDhyObservationsApiCall(action: ReturnType<typeof contactDetailsActions.dhyObservationsRequested>) {
  try {
    yield switchToLoading(true);
    const dhyObservations: IDhyObservationResponse[] = yield call(getDhyObservations, action.payload);
    yield put(contactDetailsActions.dhyObservationsFetched(dhyObservations));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve DHY observations') }));
  } finally {
    yield switchToLoading(false);
  }
}

function* getEncObstructionApiCall(action: ReturnType<typeof contactDetailsActions.encObstructionRequested>) {
  try {
    yield switchToLoading(true);
    const encObstruction: IEncObstructionResponse = yield call(getEncObstruction, action.payload);
    yield put(contactDetailsActions.encObstructionFetched(encObstruction));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve ENC obstruction') }));
  } finally {
    yield switchToLoading(false);
  }
}

function* getEncWreckApiCall(action: ReturnType<typeof contactDetailsActions.encWreckRequested>) {
  try {
    yield switchToLoading(true);
    const encWreck: IEncWreckResponse = yield call(getEncWreck, action.payload);
    yield put(contactDetailsActions.encWreckFetched(encWreck));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve ENC wreck') }));
  } finally {
    yield switchToLoading(false);
  }
}

function* getNationalContactApiCall(action: ReturnType<typeof contactDetailsActions.nationalContactRequested>) {
  try {
    yield switchToLoading(true);
    const nationalContact: INationalContactResponse = yield call(getNationalContact, action.payload);
    yield put(contactDetailsActions.nationalContactFetched(nationalContact));

    if (!nationalContact.isDeleted) {
      yield put(contactDetailsActions.nationalContactTreeRequested(nationalContact.nationalContactNumber));
    }
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve national contact') }));
  } finally {
    yield switchToLoading(false);
  }
}

function* getRceContactApiCall(action: ReturnType<typeof contactDetailsActions.rceContactRequested>) {
  try {
    yield switchToLoading(true);
    const rceContact: IRceContactResponse = yield call(getRceContact, action.payload);
    yield put(contactDetailsActions.rceContactFetched(rceContact));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve RCE contact') }));
  } finally {
    yield switchToLoading(false);
  }
}

function* rceContactReassignApiCall(action: ReturnType<typeof contactDetailsActions.rceContactReassign>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(reassignRceContact, action.payload);
    yield put(contactDetailsActions.rceContactReassigned());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save RCE information') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* getRwsContactApiCall(action: ReturnType<typeof contactDetailsActions.rwsContactRequested>) {
  try {
    yield switchToLoading(true);
    const rwsContact: IRwsContactResponse = yield call(getRwsContact, action.payload);

    if (rwsContact) {
      yield put(contactDetailsActions.rwsContactFetched(rwsContact));
      yield put(contactDetailsActions.rwsObservationsRequested(rwsContact.id));
    }
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve RWS contact') }));
  } finally {
    yield switchToLoading(false);
  }
}

function* getRwsObservationsApiCall(action: ReturnType<typeof contactDetailsActions.rwsObservationsRequested>) {
  try {
    yield switchToLoading(true);
    const rwsObservations: IRwsObservationResponse[] = yield call(getRwsObservations, action.payload);
    yield put(contactDetailsActions.rwsObservationsFetched(rwsObservations));
    yield put(contactDetailsActions.rwsObservationDocumentsRequested(rwsObservations.map(x => x.id)));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve RWS observations') }));
  } finally {
    yield switchToLoading(false);
  }
}

function* getRwsObservationDocumentsApiCall(action: ReturnType<typeof contactDetailsActions.rwsObservationDocumentsRequested>) {
  try {
    yield switchToLoading(true);
    const rwsObservationDocuments: IRwsObservationDocumentResponse[] = yield call(getRwsObservationDocuments, action.payload);
    yield put(contactDetailsActions.rwsObservationDocumentsFetched(rwsObservationDocuments));
  } catch {
    yield put(
      notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve RWS observation documents') })
    );
  } finally {
    yield switchToLoading(false);
  }
}

function* getSalvageInfoApiCall(action: ReturnType<typeof contactDetailsActions.salvageInfoRequested>) {
  try {
    yield switchToLoading(true);
    const salvageInfo: ISalvageInfoResponse = yield call(getSalvageInfo, action.payload);
    yield put(contactDetailsActions.salvageInfoFetched(salvageInfo));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve salvage info') }));
  } finally {
    yield switchToLoading(false);
  }
}

function* getWreckInfoApiCall(action: ReturnType<typeof contactDetailsActions.wreckInfoRequested>) {
  try {
    yield switchToLoading(true);
    const wreckInfo: IWreckInfoResponse = yield call(getWreckInfo, action.payload);
    yield put(contactDetailsActions.wreckInfoFetched(wreckInfo));
    yield put(contactDetailsActions.wreckDocumentsRequested(action.payload));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve wreck info') }));
  } finally {
    yield switchToLoading(false);
  }
}

function* getWreckDocumentsApiCall(action: ReturnType<typeof contactDetailsActions.wreckDocumentsRequested>) {
  try {
    yield switchToLoading(true);
    const wreckDocuments: IWreckDocumentResponse[] = yield call(getWreckDocuments, action.payload);
    yield put(contactDetailsActions.wreckDocumentsFetched(wreckDocuments));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve wreck documents') }));
  } finally {
    yield switchToLoading(false);
  }
}

function* salvageInfoCreateApiCall(action: ReturnType<typeof contactDetailsActions.salvageInfoCreate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(createSalvageInfo, action.payload);
    yield put(contactDetailsActions.salvageInfoRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save salvage information') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* salvageInfoUpdateApiCall(action: ReturnType<typeof contactDetailsActions.salvageInfoUpdate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(updateSalvageInfo, action.payload);
    yield put(contactDetailsActions.salvageInfoRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save salvage information') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* salvageInfoDeleteApiCall(action: ReturnType<typeof contactDetailsActions.salvageInfoDelete>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(deleteSalvageInfo, action.payload);
    yield put(contactDetailsActions.salvageInfoDeleted());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to delete salvage information') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* wreckInfoCreateApiCall(action: ReturnType<typeof contactDetailsActions.wreckInfoCreate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(createWreckInfo, action.payload);
    yield put(contactDetailsActions.wreckInfoRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save wreck information') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* wreckInfoUpdateApiCall(action: ReturnType<typeof contactDetailsActions.wreckInfoUpdate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(updateWreckInfo, action.payload);
    yield put(contactDetailsActions.wreckInfoRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save wreck information') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* wreckInfoDeleteApiCall(action: ReturnType<typeof contactDetailsActions.wreckInfoDelete>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(deleteWreckInfo, action.payload);
    yield put(contactDetailsActions.wreckInfoDeleted());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to delete wreck information') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* wreckInfoReassignApiCall(action: ReturnType<typeof contactDetailsActions.wreckInfoReassign>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(reassignWreckInfo, action.payload);
    yield put(contactDetailsActions.wreckInfoReassigned());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save wreck information') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* rwsContactUpdateApiCall(action: ReturnType<typeof contactDetailsActions.rwsContactUpdate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(updateRwsContact, action.payload);
    yield put(contactDetailsActions.rwsContactRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save RWS contact') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* rwsContactDeleteApiCall(action: ReturnType<typeof contactDetailsActions.rwsContactDelete>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(deleteRwsContact, action.payload.rwsContactId);
    yield put(contactDetailsActions.contactDetailsCleanUp());
    yield put(contactDetailsActions.nationalContactRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to delete RWS contact') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* rwsObservationCreateApiCall(action: ReturnType<typeof contactDetailsActions.rwsObservationCreate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(createRwsObservation, ...[action.payload.rwsContactId, action.payload.request]);
    yield put(contactDetailsActions.contactDetailsCleanUp());
    yield put(contactDetailsActions.nationalContactRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save RWS observation') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* rwsObservationUpdateApiCall(action: ReturnType<typeof contactDetailsActions.rwsObservationUpdate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(updateRwsObservation, ...[action.payload.rwsContactId, action.payload.rwsObservationId, action.payload.request]);
    yield put(contactDetailsActions.rwsContactRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save RWS observation') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* rwsObservationDeleteApiCall(action: ReturnType<typeof contactDetailsActions.rwsObservationDelete>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(deleteRwsObservation, ...[action.payload.rwsContactId, action.payload.rwsObservationId]);
    yield put(contactDetailsActions.contactDetailsCleanUp());
    yield put(contactDetailsActions.nationalContactRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to delete RWS observation') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* onNationalContactTreeRequested(action: ReturnType<typeof contactDetailsActions.nationalContactTreeRequested>) {
  yield put(contactDetailsActions.rwsContactRequested(action.payload));
  yield put(contactDetailsActions.dhyObservationsRequested(action.payload));
  yield put(contactDetailsActions.rceContactRequested(action.payload));
  yield put(contactDetailsActions.wreckInfoRequested(action.payload));
  yield put(contactDetailsActions.salvageInfoRequested(action.payload));
  yield put(contactDetailsActions.encObstructionRequested(action.payload));
  yield put(contactDetailsActions.encWreckRequested(action.payload));
  yield put(contactDetailsActions.commentsRequested(action.payload));
}

function* rwsObservationDocumentsCreateApiCall(action: ReturnType<typeof contactDetailsActions.rwsObservationDocumentsCreate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(createRwsObservationDocuments, action.payload);
    yield put(contactDetailsActions.rwsObservationDocumentsRequested(action.payload.allRwsObservationIds));
  } catch {
    yield put(
      notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to upload RWS observation documents') })
    );
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* rwsObservationDocumentsDeleteApiCall(action: ReturnType<typeof contactDetailsActions.rwsObservationDocumentsDelete>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(deleteRwsObservationDocuments, action.payload);
    yield put(contactDetailsActions.rwsObservationDocumentsRequested(action.payload.allRwsObservationIds));
  } catch {
    yield put(
      notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to delete RWS observation documents') })
    );
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* nationalContactUpdateApiCall(action: ReturnType<typeof contactDetailsActions.nationalContactUpdate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(updateNationalContact, action.payload);
    const allRefs: IReferenceResponse[] = yield select((state: AppState) => state.commonState.references);
    const usedRef = allRefs.find(x => x.type === ReferenceType.ContactType && x.originalId === action.payload.request.contactType);

    if (usedRef) {
      //For now only one property can be updated, reloading the whole object tree is an overkill, so the propery is updated in the slice /Renat
      yield put(contactDetailsActions.nationalContactUpdated({ ...action.payload, contactTypeReference: usedRef }));
    }
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to update national contact') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* encObstructionCreateApiCall(action: ReturnType<typeof contactDetailsActions.encObstructionCreate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(createEncObstruction, action.payload);
    yield put(contactDetailsActions.encObstructionRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save ENC obstruction') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* encObstructionUpdateApiCall(action: ReturnType<typeof contactDetailsActions.encObstructionUpdate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(updateEncObstruction, action.payload);
    yield put(contactDetailsActions.encObstructionRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save ENC obstruction') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* encObstructionDeleteApiCall(action: ReturnType<typeof contactDetailsActions.encObstructionDelete>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(deleteEncObstruction, action.payload);
    yield put(contactDetailsActions.encObstructionDeleted());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to delete ENC obstruction') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* encWreckCreateApiCall(action: ReturnType<typeof contactDetailsActions.encWreckCreate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(createEncWreck, action.payload);
    yield put(contactDetailsActions.encWreckRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save ENC wreck') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* encWreckUpdateApiCall(action: ReturnType<typeof contactDetailsActions.encWreckUpdate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(updateEncWreck, action.payload);
    yield put(contactDetailsActions.encWreckRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save ENC wreck') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* encWrecDeleteApiCall(action: ReturnType<typeof contactDetailsActions.encWreckDelete>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(deleteEncWreck, action.payload);
    yield put(contactDetailsActions.encWreckDeleted());
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to delete ENC wreck') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* rceContactUpdateApiCall(action: ReturnType<typeof contactDetailsActions.rceContactUpdate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(updateRceContact, action.payload);
    const allRefs: IReferenceResponse[] = yield select((state: AppState) => state.commonState.references);
    const usedRef = allRefs.find(
      x => x.type === ReferenceType.ContactAvailability && x.originalId === action.payload.request.contactAvailability
    );

    if (usedRef) {
      //For now only one property can be updated, reloading the whole object tree is an overkill, so the property is updated in the slice /Renat
      yield put(contactDetailsActions.rceContactUpdated({ ...action.payload, contactAvailabilityReference: usedRef }));
    }
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to update RCE contact') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* dhyObservationUpdateApiCall(action: ReturnType<typeof contactDetailsActions.dhyObservationUpdate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(updateDhyObservation, action.payload);
    const allRefs: IReferenceResponse[] = yield select((state: AppState) => state.commonState.references);
    const usedRef = allRefs.find(
      x => x.type === ReferenceType.ContactAvailability && x.originalId === action.payload.request.contactAvailability
    );

    if (usedRef) {
      //For now only one property can be updated, reloading the whole object tree is an overkill, so the property is updated in the slice /Renat
      yield put(contactDetailsActions.dhyObservationUpdated({ ...action.payload, contactAvailabilityReference: usedRef }));
    }
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to update DHY observation') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* dhyObservationReassignApiCall(action: ReturnType<typeof contactDetailsActions.dhyObservationReassign>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(reassignDhyObservation, action.payload);
    yield put(contactDetailsActions.dhyObservationReassigned(action.payload.dhyObservationId));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save DHY information') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* rwsObservationReassignApiCall(action: ReturnType<typeof contactDetailsActions.rwsObservationReassign>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(reassignRwsObservation, action.payload);
    yield put(contactDetailsActions.contactDetailsCleanUp());
    yield put(contactDetailsActions.nationalContactRequested(action.payload.request.fromNcn));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save RWS information') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* rwsContactReassignApiCall(action: ReturnType<typeof contactDetailsActions.rwsContactReassign>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(reassignRwsContact, action.payload);
    yield put(contactDetailsActions.contactDetailsCleanUp());
    yield put(contactDetailsActions.nationalContactRequested(action.payload.fromNcn));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to save RWS information') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* searchForReassignRequestedApiCall(action: ReturnType<typeof contactDetailsActions.searchForReassignRequested>) {
  try {
    yield put(loadingActions.enableLoading(action.payload.loadingKey));
    yield put(contactDetailsActions.searchForReassignFetched([]));
    const contacts: number[] = yield call(searchForReassign, ...[action.payload.entityType, action.payload.term]);
    yield put(contactDetailsActions.searchForReassignFetched(contacts));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to retrieve contacts') }));
  } finally {
    yield put(loadingActions.disableLoading(action.payload.loadingKey));
  }
}

function* wreckDocumentsCreateApiCall(action: ReturnType<typeof contactDetailsActions.wreckDocumentsCreate>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(createWreckDocuments, action.payload);
    yield put(contactDetailsActions.wreckDocumentsRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to upload wreck documents') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* wreckDocumentsDeleteApiCall(action: ReturnType<typeof contactDetailsActions.wreckDocumentsDelete>) {
  try {
    yield put(commonActions.saveInProgressChanged(true));
    yield call(deleteWreckDocuments, action.payload);
    yield put(contactDetailsActions.wreckDocumentsRequested(action.payload.nationalContactNumber));
  } catch {
    yield put(notificationActions.push({ type: 'error', title: i18n.t('Failed'), body: i18n.t('Failed to delete wreck documents') }));
  } finally {
    yield put(commonActions.saveInProgressChanged(false));
  }
}

function* commentCreateHandler() {
  yield takeLatest(contactDetailsActions.commentCreate, commentCreateApiCall);
}

function* salvageInfoCreateHandler() {
  yield takeLatest(contactDetailsActions.salvageInfoCreate, salvageInfoCreateApiCall);
}

function* salvageInfoUpdateHandler() {
  yield takeLatest(contactDetailsActions.salvageInfoUpdate, salvageInfoUpdateApiCall);
}

function* salvageInfoDeleteHandler() {
  yield takeLatest(contactDetailsActions.salvageInfoDelete, salvageInfoDeleteApiCall);
}

function* wreckInfoCreateHandler() {
  yield takeLatest(contactDetailsActions.wreckInfoCreate, wreckInfoCreateApiCall);
}

function* wreckInfoUpdateHandler() {
  yield takeLatest(contactDetailsActions.wreckInfoUpdate, wreckInfoUpdateApiCall);
}

function* wreckInfoDeleteHandler() {
  yield takeLatest(contactDetailsActions.wreckInfoDelete, wreckInfoDeleteApiCall);
}

function* wreckInfoReassignHandler() {
  yield takeLatest(contactDetailsActions.wreckInfoReassign, wreckInfoReassignApiCall);
}

function* rwsContactUpdateHandler() {
  yield takeLatest(contactDetailsActions.rwsContactUpdate, rwsContactUpdateApiCall);
}

function* rwsContactDeleteHandler() {
  yield takeLatest(contactDetailsActions.rwsContactDelete, rwsContactDeleteApiCall);
}

function* rwsObservationCreateHandler() {
  yield takeLatest(contactDetailsActions.rwsObservationCreate, rwsObservationCreateApiCall);
}

function* rwsObservationUpdateHandler() {
  yield takeLatest(contactDetailsActions.rwsObservationUpdate, rwsObservationUpdateApiCall);
}

function* rwsObservationDeleteHandler() {
  yield takeLatest(contactDetailsActions.rwsObservationDelete, rwsObservationDeleteApiCall);
}

function* nationalContactTreeRequestedHandler() {
  yield takeLatest(contactDetailsActions.nationalContactTreeRequested, onNationalContactTreeRequested);
}

function* rwsObservationDocumentsCreateHandler() {
  yield takeLatest(contactDetailsActions.rwsObservationDocumentsCreate, rwsObservationDocumentsCreateApiCall);
}

function* rwsObservationDocumentsDeleteHandler() {
  yield takeLatest(contactDetailsActions.rwsObservationDocumentsDelete, rwsObservationDocumentsDeleteApiCall);
}

function* nationalContactUpdateHandler() {
  yield takeLatest(contactDetailsActions.nationalContactUpdate, nationalContactUpdateApiCall);
}

function* encObstructionCreateHandler() {
  yield takeLatest(contactDetailsActions.encObstructionCreate, encObstructionCreateApiCall);
}

function* encObstructionUpdateHandler() {
  yield takeLatest(contactDetailsActions.encObstructionUpdate, encObstructionUpdateApiCall);
}

function* encObstructionDeleteHandler() {
  yield takeLatest(contactDetailsActions.encObstructionDelete, encObstructionDeleteApiCall);
}

function* encWreckCreateHandler() {
  yield takeLatest(contactDetailsActions.encWreckCreate, encWreckCreateApiCall);
}

function* encWreckUpdateHandler() {
  yield takeLatest(contactDetailsActions.encWreckUpdate, encWreckUpdateApiCall);
}

function* encWreckDeleteHandler() {
  yield takeLatest(contactDetailsActions.encWreckDelete, encWrecDeleteApiCall);
}

function* rceContactUpdateHandler() {
  yield takeLatest(contactDetailsActions.rceContactUpdate, rceContactUpdateApiCall);
}

function* rceContactReassignHandler() {
  yield takeLatest(contactDetailsActions.rceContactReassign, rceContactReassignApiCall);
}

function* dhyObservationUpdateHandler() {
  yield takeLatest(contactDetailsActions.dhyObservationUpdate, dhyObservationUpdateApiCall);
}

function* dhyObservationReassignHandler() {
  yield takeLatest(contactDetailsActions.dhyObservationReassign, dhyObservationReassignApiCall);
}

function* rwsObservationReassignHandler() {
  yield takeLatest(contactDetailsActions.rwsObservationReassign, rwsObservationReassignApiCall);
}

function* rwsContactReassignHandler() {
  yield takeLatest(contactDetailsActions.rwsContactReassign, rwsContactReassignApiCall);
}

function* searchForReassignRequestedHandler() {
  yield takeLatest(contactDetailsActions.searchForReassignRequested, searchForReassignRequestedApiCall);
}

function* wreckDocumentsCreateHandler() {
  yield takeLatest(contactDetailsActions.wreckDocumentsCreate, wreckDocumentsCreateApiCall);
}

function* wreckDocumentsDeleteHandler() {
  yield takeLatest(contactDetailsActions.wreckDocumentsDelete, wreckDocumentsDeleteApiCall);
}

function* getContactDetailsHandler() {
  yield takeLatest(contactDetailsActions.commentsRequested, getCommentsApiCall);
  yield takeLatest(contactDetailsActions.dhyObservationsRequested, getDhyObservationsApiCall);
  yield takeLatest(contactDetailsActions.encObstructionRequested, getEncObstructionApiCall);
  yield takeLatest(contactDetailsActions.encWreckRequested, getEncWreckApiCall);
  yield takeLatest(contactDetailsActions.nationalContactRequested, getNationalContactApiCall);
  yield takeLatest(contactDetailsActions.rceContactRequested, getRceContactApiCall);
  yield takeLatest(contactDetailsActions.rwsContactRequested, getRwsContactApiCall);
  yield takeLatest(contactDetailsActions.rwsObservationDocumentsRequested, getRwsObservationDocumentsApiCall);
  yield takeLatest(contactDetailsActions.rwsObservationsRequested, getRwsObservationsApiCall);
  yield takeLatest(contactDetailsActions.salvageInfoRequested, getSalvageInfoApiCall);
  yield takeLatest(contactDetailsActions.wreckDocumentsRequested, getWreckDocumentsApiCall);
  yield takeLatest(contactDetailsActions.wreckInfoRequested, getWreckInfoApiCall);
}

export function* contactDetailsSaga(): Generator {
  yield all([
    getContactDetailsHandler(),
    commentCreateHandler(),
    salvageInfoCreateHandler(),
    salvageInfoUpdateHandler(),
    salvageInfoDeleteHandler(),
    wreckInfoCreateHandler(),
    wreckInfoUpdateHandler(),
    wreckInfoDeleteHandler(),
    rwsContactUpdateHandler(),
    rwsContactDeleteHandler(),
    rwsObservationCreateHandler(),
    rwsObservationUpdateHandler(),
    rwsObservationDeleteHandler(),
    nationalContactTreeRequestedHandler(),
    rwsObservationDocumentsCreateHandler(),
    rwsObservationDocumentsDeleteHandler(),
    nationalContactUpdateHandler(),
    encObstructionCreateHandler(),
    encObstructionUpdateHandler(),
    encObstructionDeleteHandler(),
    encWreckCreateHandler(),
    encWreckUpdateHandler(),
    encWreckDeleteHandler(),
    rceContactUpdateHandler(),
    dhyObservationUpdateHandler(),
    searchForReassignRequestedHandler(),
    wreckInfoReassignHandler(),
    rceContactReassignHandler(),
    dhyObservationReassignHandler(),
    rwsObservationReassignHandler(),
    rwsContactReassignHandler(),
    wreckDocumentsCreateHandler(),
    wreckDocumentsDeleteHandler()
  ]);
}
